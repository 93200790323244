import { useState } from "react";
import axios from "axios";
import { getCurrentSession } from "./auth";

export const useUploadFile = () => {
  const [uploadProgress, setProgress] = useState(0);
  const [uploadError, setError] = useState();
  const [uploadCompleted, setCompleted] = useState();
  const [response, setResponse] = useState();

  const resetState = () => {
    if (uploadError) setError(null);
    if (response) setResponse(null);
    if (uploadCompleted) setCompleted(null);
    if (uploadProgress > 0) setProgress(0);
  };

  const uploadFile = async ({ file, endpoint }) => {
    const session = await getCurrentSession();
    const token = session.idToken.jwtToken;
    const data = new FormData();
    data.append("file", file, file.name);
    try {
      resetState();
      const res = await axios.post(endpoint, data, {
        onUploadProgress: ProgressEvent => {
          console.log((ProgressEvent.loaded / ProgressEvent.total) * 100);
          setProgress((ProgressEvent.loaded / ProgressEvent.total) * 100);
        },
        headers: {
          Authorization: token
        }
      });

      setCompleted(true);
      setResponse(res.data);
    } catch (e) {
      console.log(e.response, e.error, e.message);
      setError(e.response ? e.response.data : e);
    }
  };

  return { uploadFile, uploadProgress, uploadError, uploadCompleted, response };
};
