import React from "react";
import { Link } from "@reach/router";
import {
  Container,
  MenuItem,
  MenuList,
  ImportPlacesIcon,
  BrandsIcon,
  TagsIcon,
  SourceIcon
} from "./styles";

export default () => {
  return (
    <Container>
      <MenuList>
        <MenuItem>
          <ImportPlacesIcon /> <Link to="/">Import </Link>
        </MenuItem>
        <MenuItem>
          <BrandsIcon /> <Link to="/brands">Brands </Link>
        </MenuItem>
        <MenuItem>
          <TagsIcon /> <Link to="/tags">Tags </Link>
        </MenuItem>
        <MenuItem>
          <SourceIcon />
          <Link to="/sources">Sources </Link>
        </MenuItem>
      </MenuList>
    </Container>
  );
};
