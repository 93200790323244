import React, { useState, useRef, useCallback, useEffect } from "react";
import { Table, CheckboxContainer } from "./styles";
import TableHeader from "../TableHeader/TableHeader";
import TableCell from "../TableCell/TableCell";
import { Checkbox } from "@smooth-ui/core-sc";
import { AutoSizer } from "react-virtualized";
import { debounce } from "../../utils/functions";
import ReactTooltip from "react-tooltip";

import "react-virtualized/styles.css";

export default ({
  fields,
  data,
  onSelectAll,
  onSelectRow,
  onSort,
  selectable,
  height = 420,
  emptyRows = 15,
  fixCheckboxes = true,
  sortField,
  sortDirection,
  fixedColumnCount
}) => {
  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const tableRef = useRef(null);
  const tooltipRef = useRef(null);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [data]);

  const tableMinWidth = fields.reduce((total, field) => {
    total += field.width || 0;
    return total;
  }, 0);

  const getColumnWidth = (tableWidth, { index }) => {
    if (index === 0 && selectable) return 30;
    const realWidth = selectable ? tableWidth - 30 : tableWidth;

    const field = selectable ? fields[index - 1] : fields[index];
    const fieldMinWith = field.width;
    const ratio = fieldMinWith / tableMinWidth;
    const currentWidth = realWidth * ratio;
    const finalWidth =
      currentWidth > fieldMinWith ? currentWidth : fieldMinWith;

    if (index === fields.length) return finalWidth;

    return finalWidth;
  };

  const cellRenderer = ({ columnIndex, rowIndex, key, style, cellData }) => {
    if (columnIndex === 0 && rowIndex === 0 && selectable) {
      return (
        <CheckboxContainer vStyle={style} key={key} header={true}>
          <Checkbox
            size="sm"
            checked={allRowsSelected}
            onChange={() => {
              onSelectAll(!allRowsSelected);
              setAllRowsSelected(!allRowsSelected);
            }}
          />
        </CheckboxContainer>
      );
    }

    const field =
      columnIndex > 0 && selectable
        ? fields[columnIndex - 1]
        : fields[columnIndex];

    if (rowIndex === 0) {
      return (
        <TableHeader
          vStyle={style}
          key={key}
          field={field}
          onSort={onSort}
          isSorted={field.name === sortField}
          sortDirection={sortDirection}
        />
      );
    }

    const row = data[rowIndex - 1] || {};

    if (columnIndex === 0 && selectable) {
      if (!Object.keys(row).length)
        return <TableCell vStyle={style} key={key} field={field} row={row} />;
      return (
        <CheckboxContainer vStyle={style} key={`${key}-${row.id}`}>
          <Checkbox
            size="sm"
            checked={row.isSelected}
            onChange={e => onSelectRow(row)}
          />
        </CheckboxContainer>
      );
    }

    return <TableCell vStyle={style} key={key} field={field} row={row} />;
  };

  const recomputeWidthOnResize = useCallback(
    debounce(() => {
      if (tableRef.current && tableRef.current.recomputeGridSize) {
        tableRef.current.recomputeGridSize();
      }
    }, 200),
    []
  );

  const rowCount = data.length + 2 >= emptyRows ? data.length + 2 : emptyRows;
  const rowHeight = 35;

  return (
    <>
      <ReactTooltip id="tooltip" ref={tooltipRef} border />
      <AutoSizer disableHeight onResize={recomputeWidthOnResize}>
        {({ width }) => {
          // const currentHeight = rowHeight * rowCount;
          return (
            <Table
              ref={tableRef}
              cellRenderer={cellRenderer}
              onSectionRendered={() => {
                ReactTooltip.rebuild();
              }}
              columnCount={selectable ? fields.length + 1 : fields.length}
              columnWidth={getColumnWidth.bind(null, width)}
              height={height}
              //height={currentHeight < height ? currentHeight : height}
              fixedColumnCount={
                fixedColumnCount || (selectable && fixCheckboxes ? 1 : 0)
              }
              fixedRowCount={1}
              hideTopRightGridScrollbar
              hideBottomLeftGridScrollbar
              rowCount={rowCount}
              rowHeight={rowHeight}
              width={width}
            />
          );
        }}
      </AutoSizer>
    </>
  );
};
