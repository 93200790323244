export default {
  borderColor: "#d7dfe3",
  borderRadius: "4px",
  boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.05)",
  darkGrey: "#67757c",
  grey: "#d6e4eb",
  lightGrey: "#fafafa",
  blue: "#1e88e5",
  red: "#bd4932",
  lightRed: "#BE6464",
  orange: "#F0AD4E",
  green: "#8DC572"
};
