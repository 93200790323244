import React from "react";
import {
  Container,
  ErrorContainer,
  ButtonContainer,
  ConfirmButton,
  CancelButton,
  WarningIcon,
  ConfirmationContainer,
  StyledSpinner
} from "./styles";

export default ({
  useRequest,
  hideModal,
  confirmationMessage,
  loadingMessage,
  errorMessage = "An error occurred."
}) => {
  const { makeRequest, loading, error, status } = useRequest();
  console.log(status);
  if (status === 200) hideModal();

  const onConfirm = () => {
    makeRequest();
  };

  const renderError = () => (
    <ErrorContainer>
      <WarningIcon />
      {errorMessage} <p>{error}</p>
    </ErrorContainer>
  );

  const renderLoading = () => (
    <>
      <StyledSpinner /> {loadingMessage}
    </>
  );

  const renderConfirmation = () => (
    <ConfirmationContainer>
      <p>{confirmationMessage}</p>
      <ButtonContainer>
        <ConfirmButton onClick={onConfirm}>Confirm</ConfirmButton>
        <CancelButton onClick={hideModal}>Cancel</CancelButton>
      </ButtonContainer>
    </ConfirmationContainer>
  );

  const renderContent = () => {
    if (error) return renderError();
    if (loading) return renderLoading();
    return renderConfirmation();
  };

  return <Container>{renderContent()}</Container>;
};
