import React from "react";
import { format } from "date-fns";
import tagsStore from "../../store/tags";
import { useModal } from "../../utils/hooks";
import { Spinner } from "../../utils/styles";
import {
  Container,
  StyledSpinner,
  TableActions,
  DeleteButton,
  MessageContainer,
  ActionButtonsContainer
} from "../styles";
import { InsertButton } from "./styles";

import DeleteConfirmation from "../../modals/DeleteConfirmation/DeleteConfirmation";
import CreateTag from "../../modals/CreateTag/CreateTag";

import Table from "../Table/Table";
import Search from "../Search/Search";

export default () => {
  const {
    data,
    error,
    loading,
    sortField,
    sortDirection,
    numElemsSelected,
    firstLoad
  } = tagsStore.useData();
  const sort = tagsStore.useSort();
  const {
    currentFilter,
    filterField,
    setCurrentFilter,
    setFilterField
  } = tagsStore.useFilter();
  const toggleTag = tagsStore.useToggleElement();
  const toggleAllTags = tagsStore.useToggleAllElements();
  const {
    makeRequest: updatetags,
    loading: updateLoading
  } = tagsStore.usePutNewData();

  const [ShowDeleteConfirmationModal] = useModal(
    () => (
      <DeleteConfirmation
        useRequest={tagsStore.useDeleteSelected}
        confirmationMessage={`${numElemsSelected} tags will be deleted.
        Do you want to continue?`}
        loadingMessage={"Deleting tags..."}
      />
    ),
    {
      variables: [numElemsSelected]
    }
  );

  const [showCreateTagModal] = useModal(() => <CreateTag />, {
    variables: []
  });

  const onEditField = row => {
    updatetags({ data: [row] });
  };

  const fields = [
    { name: "mn_tag_name", width: 150, label: "Tag Name", onEdit: onEditField },
    {
      name: "created_at",
      width: 100,
      label: "Created At",
      format: value => value && format(new Date(value), "YYYY-MM-DD")
    },
    {
      name: "updated_at",
      width: 100,
      label: "Updated At",
      format: value => value && format(new Date(value), "YYYY-MM-DD")
    },
    {
      name: "deleted_at",
      width: 100,
      label: "Deleted At",
      format: value => value && format(new Date(value), "YYYY-MM-DD")
    }
  ];

  const onFilterChange = e => {
    const value = e.target.value;
    setCurrentFilter({ value });
  };

  const onFilterFieldChange = e => {
    const value = e.target.value;
    setFilterField({ field: value });
  };

  const onSelectAll = allRowsSelected => {
    toggleAllTags({ selected: allRowsSelected });
  };

  const onSelecttag = tag => {
    toggleTag({ id: tag.mn_tag_id });
  };

  const renderMessage = () => {
    if (error)
      return <span>There was an error loading tags. {error.message}</span>;
    if (updateLoading) {
      return (
        <>
          <StyledSpinner />
          Updating tag...
        </>
      );
    }
    if (loading)
      return (
        <span>
          <Spinner /> Loading tags...
        </span>
      );
    if (numElemsSelected)
      return (
        <span>
          {numElemsSelected} {numElemsSelected === 1 ? "tag" : "tags"} selected
        </span>
      );
  };

  return (
    <Container>
      <TableActions>
        {firstLoad && (
          <ActionButtonsContainer>
            <InsertButton onClick={showCreateTagModal}>New tag</InsertButton>
            <DeleteButton
              onClick={ShowDeleteConfirmationModal}
              disabled={numElemsSelected === 0}
            >
              Delete
            </DeleteButton>
          </ActionButtonsContainer>
        )}
        <MessageContainer>{renderMessage()}</MessageContainer>
        {firstLoad && (
          <Search
            currentFilter={currentFilter}
            filterField={filterField}
            onFilterChange={onFilterChange}
            onFilterFieldChange={onFilterFieldChange}
            fields={fields}
          />
        )}
      </TableActions>
      <Table
        selectable
        height={800}
        emptyRows={25}
        fixCheckboxes={false}
        fields={fields}
        sortField={sortField}
        sortDirection={sortDirection}
        data={data}
        onSort={sort}
        onSelectAll={onSelectAll}
        onSelectRow={onSelecttag}
      />
    </Container>
  );
};
