import { useEffect } from "react";

import { useRequest } from "../utils/request";
import { useStore } from "./index";

const sortData = ({ data, dataEntities, field, direction, idKey }) => {
  const sortedData = data.sort((e1, e2) => {
    const elem1 = dataEntities[e1];
    const elem2 = dataEntities[e2];
    if (!field) {
      if (elem1[idKey] < elem2[idKey]) return -1;
      return 1;
    }

    if (direction === "ASC") {
      if (elem1[field] < elem2[field]) return -1;
      return 1;
    }

    if (elem1[field] > elem2[field]) return -1;
    return 1;
  });

  return sortedData;
};

export const initState = {
  currentData: [],
  dataEntities: {},
  dataBkp: [],
  filter: "",
  filterField: "name",
  sortField: null,
  sortDirection: null,
  firstLoad: false
};

const useLoader = storeKey => {
  const [state, setState] = useStore();
  const { makeRequest, loading, error, response, status } = useRequest();

  const fetch = ({ useCache = true, ...rest }) => {
    if (state[storeKey].firstLoad && useCache) return; //don't fetch again if already fetched or fetching
    makeRequest(rest);
  };

  useEffect(() => {
    if (response) {
      const idKey = state[storeKey].idKey;
      setState(state => {
        state[storeKey].currentData = response.map(elem => elem[idKey]);
        state[storeKey].dataBkp = state[storeKey].currentData.slice();
        state[storeKey].firstLoad = true;
        response.forEach(
          elem => (state[storeKey].dataEntities[elem[idKey]] = elem)
        );
      });
    }
  }, [response]);

  return {
    makeRequest: fetch,
    loading,
    error,
    response,
    status,
    loaded: state[storeKey].firstLoad
  };
};

const useEntities = ({ storeKey, endpoint }) => {
  const [state] = useStore();
  const stateElem = state[storeKey];
  const { makeRequest, ...rest } = useLoader(storeKey);
  useEffect(() => {
    makeRequest({ endpoint, method: "GET" });
  }, []);

  return { entities: stateElem.dataEntities, ...rest };
};

const useData = ({ storeKey, endpoint }) => {
  const { makeRequest, ...rest } = useLoader(storeKey);
  const [state] = useStore();
  const stateElem = state[storeKey];

  useEffect(() => {
    makeRequest({ endpoint, method: "GET" });
  }, []);

  const numElemsSelected = stateElem.currentData.reduce((acc, elemId) => {
    if (stateElem.dataEntities[elemId].isSelected) return acc + 1;
    return acc;
  }, 0);

  const dataArr = stateElem.currentData.map(
    elemId => stateElem.dataEntities[elemId]
  );

  return { ...stateElem, numElemsSelected, data: dataArr, ...rest };
};

// inserts new data to the store and the database
export const usePostNewData = ({ storeKey, endpoint }) => {
  const { makeRequest, loading, error, response, status } = useRequest();
  const insertNewDataToStore = useInsertNewData({ storeKey, endpoint });

  const postRequest = ({ data }) => {
    makeRequest({ endpoint, data, method: "POST" });
  };

  useEffect(() => {
    if (status === 200) {
      insertNewDataToStore({ data: response });
    }
  }, [status]);

  return { makeRequest: postRequest, loading, error, response, status };
};

export const usePutNewData = ({ storeKey, endpoint }) => {
  const { makeRequest, response, ...rest } = useRequest();
  const updateData = useUpdateData({ storeKey, endpoint });

  const putRequest = ({ data, query }) => {
    data.forEach(elem => {
      delete elem.isSelected;
    });

    makeRequest({ endpoint, query, data, method: "PUT" });
  };

  useEffect(() => {
    if (response) {
      updateData({ data: response });
    }
  }, [response]);
  return { makeRequest: putRequest, response, ...rest };
};

// updates existing store data
export const useUpdateData = ({ storeKey, endpoint }) => {
  const [, setState] = useStore();

  const updateData = ({ data }) => {
    setState(state => {
      const idKey = state[storeKey].idKey;
      data.forEach(elem => {
        if (!state[storeKey].dataEntities[elem[idKey]]) return;
        state[storeKey].dataEntities[elem[idKey]] = {
          ...elem,
          isSelected: state[storeKey].dataEntities[elem[idKey]].isSelected
        };
      });
    });
  };

  return updateData;
};

// inserts new data to the store
export const useInsertNewData = ({ storeKey, endpoint }) => {
  const [, setState] = useStore();

  const insertNewData = ({ data }) => {
    setState(state => {
      const idKey = state[storeKey].idKey;
      state[storeKey].dataBkp = data
        .map(elem => elem[idKey])
        .concat(state[storeKey].dataBkp);
      data.forEach(elem => {
        state[storeKey].dataEntities[elem[idKey]] = elem;
      });
      state[storeKey].currentData = state[storeKey].dataBkp;
      state[storeKey].filter = "";
      state[storeKey].filterField = state[storeKey].defaultFilterField;
      state[storeKey].sortField = null;
      state[storeKey].sortDirection = null;
    });
  };

  return insertNewData;
};

export const useDeleteData = ({ storeKey, endpoint }) => {
  const [state, setState] = useStore();
  const stateElem = state[storeKey];

  const deleteData = ({ data }) => {
    setState(state => {
      data.forEach(id => delete state[storeKey].dataEntities[id]);
      state[storeKey].currentData = stateElem.currentData.filter(
        id => data.indexOf(id) < 0
      );
      state[storeKey].dataBkp = stateElem.dataBkp.filter(
        id => data.indexOf(id) < 0
      );
    });
  };

  return deleteData;
};

export const useDelete = ({ storeKey, endpoint }) => {
  const { makeRequest, status, data, ...rest } = useRequest();
  const deleteDataFromTheStore = useDeleteData({ storeKey, endpoint });

  const deleteRequest = ({ data }) => {
    console.log(data);
    makeRequest({ endpoint, data, method: "POST" });
  };

  useEffect(() => {
    if (status === 200) {
      deleteDataFromTheStore({ data });
    }
  }, [status]);

  return { makeRequest: deleteRequest, status, data, ...rest };
};

export const useSelected = ({ storeKey, endpoint }) => {
  const [state] = useStore();
  const stateElem = state[storeKey];

  return stateElem.currentData
    .filter(id => stateElem.dataEntities[id].isSelected)
    .map(id => stateElem.dataEntities[id]);
};

export const useSelectedIds = ({ storeKey, endpoint }) => {
  const [state] = useStore();
  const stateElem = state[storeKey];

  return stateElem.currentData.filter(
    id => stateElem.dataEntities[id].isSelected
  );
};

export const useDeleteSelected = ({ storeKey, endpoint }) => {
  const selectedIds = useSelectedIds({ storeKey, endpoint });
  const { makeRequest, ...rest } = useDelete({
    storeKey,
    endpoint
  });

  const deleteSelectedData = () => {
    makeRequest({ endpoint, data: selectedIds });
  };

  return { makeRequest: deleteSelectedData, ...rest };
};

const useSort = ({ storeKey, endpoint }) => {
  const [state, setState] = useStore();
  const stateElem = state[storeKey];
  return ({ field, direction }) => {
    setState(state => {
      state[storeKey].sortField = field && field.name;
      state[storeKey].sortDirection = direction;
      state[storeKey].currentData = sortData({
        data: stateElem.currentData,
        dataEntities: stateElem.dataEntities,
        field: field && field.name,
        direction,
        idKey: stateElem.idKey
      });
    });
  };
};

const useQuery = ({ storeKey }) => {
  const [state] = useStore();
  const stateElem = state[storeKey];

  const query = ({ field, value }) => {
    console.log(field, value);
    return stateElem.dataBkp
      .filter(elemId => {
        const elem = stateElem.dataEntities[elemId];
        return elem[field] && elem[field] === value;
      })
      .map(elemId => stateElem.dataEntities[elemId]);
  };

  return query;
};

const useFilter = ({ storeKey, endpoint }) => {
  const [state, setState] = useStore();
  const stateElem = state[storeKey];
  const applyFilter = ({ value, field = stateElem.filterField }) => {
    setState(state => {
      state[storeKey].filter = value;

      if (!value) {
        state[storeKey].currentData = stateElem.dataBkp;
        return;
      }

      state[storeKey].currentData = stateElem.dataBkp.filter(elemId => {
        const elem = stateElem.dataEntities[elemId];
        return elem[field] && elem[field].indexOf(value) >= 0;
      });
    });
  };
  const setFilterField = ({ field }) => {
    setState(state => {
      state[storeKey].filterField = field;
      if (state[storeKey].filter) {
        state[storeKey].currentData = stateElem.dataBkp.filter(elemId => {
          const elem = stateElem.dataEntities[elemId];
          return (
            elem[field] && elem[field].indexOf(state[storeKey].filter) >= 0
          );
        });
      }
    });
  };

  return {
    currentFilter: stateElem.filter,
    setCurrentFilter: applyFilter,
    setFilterField,
    filterField: stateElem.filterField
  };
};

const useSelectElement = ({ storeKey, endpoint }) => {
  const [, setState] = useStore();
  return ({ id }) => {
    setState(state => {
      state[storeKey].dataEntities[id].isSelected = true;
    });
  };
};

const useToggleElement = ({ storeKey, endpoint }) => {
  const [state, setState] = useStore();
  const stateElem = state[storeKey];
  return ({ id }) => {
    setState(state => {
      state[storeKey].dataEntities[id].isSelected = !stateElem.dataEntities[id]
        .isSelected;
    });
  };
};

const useToggleAllElements = ({ storeKey, endpoint }) => {
  const [, setState] = useStore();
  return ({ selected }) => {
    setState(state => {
      state[storeKey].currentData.forEach(
        elemId => (state[storeKey].dataEntities[elemId].isSelected = selected)
      );
    });
  };
};

export const createDataStore = ({
  storeKey,
  idKey = "id",
  endpoint,
  deleteEndpoint,
  filterField = "name"
}) => {
  initState.idKey = idKey;
  return {
    useData: useData.bind(null, { storeKey, endpoint }),
    useEntities: useEntities.bind(null, { storeKey, endpoint }),
    useInsertNewData: useInsertNewData.bind(null, { storeKey, endpoint }),
    useDeleteData: useDeleteData.bind(null, { storeKey, endpoint }),
    usePostNewData: usePostNewData.bind(null, { storeKey, endpoint }),
    usePutNewData: usePutNewData.bind(null, { storeKey, endpoint }),
    useUpdateData: useUpdateData.bind(null, { storeKey, endpoint }),
    useLoader: useLoader.bind(null, { storeKey, endpoint }),
    useSort: useSort.bind(null, { storeKey, endpoint }),
    useFilter: useFilter.bind(null, { storeKey, endpoint }),
    useQuery: useQuery.bind(null, { storeKey, endpoint }),
    useSelectElement: useSelectElement.bind(null, { storeKey, endpoint }),
    useToggleElement: useToggleElement.bind(null, { storeKey, endpoint }),
    useToggleAllElements: useToggleAllElements.bind(null, {
      storeKey,
      endpoint
    }),
    useDelete: useDelete.bind(null, {
      storeKey,
      endpoint: deleteEndpoint || endpoint
    }),
    useDeleteSelected: useDeleteSelected.bind(null, {
      storeKey,
      endpoint: deleteEndpoint || endpoint
    }),
    useSelected: useSelected.bind(null, { storeKey, endpoint }),
    useSelectedIds: useSelectedIds.bind(null, storeKey),
    initState: { ...initState, filterField, defaultFilterField: filterField }
  };
};
