import styled from "styled-components";
import { Router } from "@reach/router";

import { Box } from "../utils/styles";

export const Container = styled.div`
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-columns: ${props =>
    props.expandedMenu ? "auto 90%" : "0% 100%"};
  grid-template-rows: 75px auto;
  grid-template-areas:
    "header header"
    "menu main";
`;

export const Main = styled.div`
  grid-area: main;
  background-color: #d6e4eb;
  padding: 10px;
`;

export const MainContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: ${props => props.theme.darkGrey};
  background-color: ${props => props.theme.lightGrey};
  padding: 10px 10px 10px 10px;
`;

export const StyledRouter = styled(Router)`
  width: 100%;
  height: 100%;
`;
