import styled from "styled-components";
import { Warning } from "styled-icons/typicons/Warning";
import { ButtonSuccess, ButtonDanger, Spinner } from "../utils/styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${props => props.height + 20}px;
  margin-bottom: 10px;
`;

export const ConfirmationPopup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  font-size: 15px;
`;

export const InfoPopup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1px;
  font-size: 13px;
  > p {
    margin: 0px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 5px;
`;

export const ConfirmButton = styled(ButtonSuccess)`
  margin-right: 3px;
  padding: 4px 6px;
`;
export const CancelButton = styled(ButtonDanger)`
  padding: 4px 6px;
`;

export const CloseButton = styled(ButtonSuccess)`
  width: 90px;
  padding: 4px;
  margin-bottom: 5px;
`;

export const StyledSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
`;

export const WarningIcon = styled(Warning)`
  width: 40px;
  height: 40px;
  margin-bottom: 2px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${props => props.theme.red};
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
`;
