import styled from "styled-components";
import { ButtonSuccess, ButtonDanger } from "../../utils/styles";

export const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const ConfirmButton = styled(ButtonSuccess)`
  margin-right: 3px;
`;
export const CancelButton = styled(ButtonDanger)``;
