import { useEffect } from "react";
import { createDataStore } from "./common";
import stagingPlacesStore from "./stagingPlaces";

const footprintsStore = createDataStore({
  storeKey: "footprints",
  endpoint: process.env.REACT_APP_PLACES_API_HOST + "/footprints",
  deleteEndpoint: process.env.REACT_APP_PLACES_API_HOST + "/deleteFootprints",
  idKey: "id"
});

const usePostNewData = () => {
  const updatePlaces = stagingPlacesStore.useUpdateData();
  const { status, response, ...rest } = footprintsStore.usePostNewData();

  useEffect(() => {
    if (status === 200) updatePlaces({ data: response });
  }, [status, response]);

  return { status, response, ...rest };
};

const usePutNewData = () => {
  const updatePlaces = stagingPlacesStore.useUpdateData();
  const { status, response, ...rest } = footprintsStore.usePutNewData();

  useEffect(() => {
    if (status === 200) updatePlaces({ data: response });
  }, [status, response]);

  return { status, response, ...rest };
};

const useDelete = () => {
  const updatePlaces = stagingPlacesStore.useUpdateData();
  const { status, response, ...rest } = footprintsStore.useDelete();

  useEffect(() => {
    if (status === 200) updatePlaces({ data: response });
  }, [status, response]);

  return { status, response, ...rest };
};

export default {
  ...footprintsStore,
  useDelete,
  usePutNewData,
  usePostNewData
};
