import styled from "styled-components";
import { Spinner } from "../../utils/styles";
import { Warning } from "styled-icons/typicons/Warning";
import Select from "react-virtualized-select";
import { ButtonSuccess, ButtonDanger, Input } from "../../utils/styles";

import "react-select/dist/react-select.css";
import "react-virtualized-select/styles.css";

export const Container = styled.div`
  height: 100%;
  font-size: 14px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BrandNameInput = styled(Input)`
  width: 250px;
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 1px;
  outline: none;
`;

export const ExistingBrands = styled.div`
  width: 250px;
  box-sizing: border-box;
  overflow: auto;
  max-height: 100px;
  border: 1px solid #ced4da;
  padding-left: 5px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WarningIcon = styled(Warning)`
  width: 40px;
  height: 40px;
  margin-bottom: 2px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${props => props.theme.red};
`;

export const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const StyledSelect = styled(Select)`
  width: ${props => props.width || 250}px;
  margin-bottom: 5px;
  margin-top: 1px;

  .Select-menu-outer {
    z-index: 1099 !important;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const ConfirmButton = styled(ButtonSuccess)`
  margin-right: 3px;
`;
export const CancelButton = styled(ButtonDanger)``;

export const StyledSpinner = styled(Spinner)`
  width: 40px;
  height: 40px;
`;
