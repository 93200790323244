import React from "react";
import { ThemeProvider } from "styled-components";
import { theme as styledTheme } from "styled-config";
import { Router } from "@reach/router";

import { StoreProvider, initialState } from "../store";
import { ModalProvider } from "react-modal-hook";
import Login from "../Login/Login";
import Main from "../Main/Main";
import theme from "./theme";

const myTheme = {
  ...styledTheme,
  ...theme
};

export default () => {
  return (
    <StoreProvider initialState={initialState}>
      <ThemeProvider theme={myTheme}>
        <ModalProvider>
          <Router>
            <Login path="/login" />
            <Main path="/*" />
          </Router>
        </ModalProvider>
      </ThemeProvider>
    </StoreProvider>
  );
};
