import styled from "styled-components";
import { Spinner } from "../../utils/styles";
import { Warning } from "styled-icons/typicons/Warning";
import Select from "react-virtualized-select";
import { ButtonSuccess, ButtonDanger } from "../../utils/styles";

import "react-select/dist/react-select.css";
import "react-virtualized-select/styles.css";

export const Container = styled.div`
  height: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const WarningIcon = styled(Warning)`
  width: 40px;
  height: 40px;
  margin-bottom: 2px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${props => props.theme.red};
`;

export const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

export const StyledSelect = styled(Select)`
  width: 300px;
  margin-bottom: 5px;

  .Select-menu-outer {
    z-index: 1099 !important;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const ConfirmButton = styled(ButtonSuccess)`
  margin-right: 3px;
`;
export const CancelButton = styled(ButtonDanger)``;

export const StyledSpinner = styled(Spinner)`
  width: 40px;
  height: 40px;
`;
