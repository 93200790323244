import React, { useState } from "react";
import brandsStore from "../../store/brands";
import tagsStore from "../../store/tags";

import {
  Container,
  ErrorContainer,
  ButtonContainer,
  ConfirmButton,
  CancelButton,
  WarningIcon,
  SelectorContainer,
  StyledSelect,
  StyledSpinner
} from "./styles";

export default props => {
  const { brand, hideModal } = props;
  const {
    data: tags,
    loading: loadingTags,
    error: errorTags
  } = tagsStore.useData();
  const selectedBrands = brandsStore.useSelected();
  const {
    loading: editingBrand,
    status: editingStatus,
    error: editingError,
    makeRequest: editBrand
  } = brandsStore.usePutNewData();
  const [value, setValue] = useState(
    brand.tags.map(tag => {
      return {
        label: tag.mn_tag_name,
        value: tag.mn_tag_id
      };
    })
  );
  const [askForConfirmation, setAskForConfirmation] = useState(false);

  const brandsToUpdate = [...selectedBrands];
  if (!brandsToUpdate.find(sb => sb.mn_brand_id === brand.mn_brand_id))
    brandsToUpdate.push(brand);

  if (editingStatus === 200) hideModal();

  const onConfirm = () => {
    if (!askForConfirmation && brandsToUpdate.length > 1)
      return setAskForConfirmation(true);
    setAskForConfirmation(false);
    console.log(value);

    editBrand({
      data: brandsToUpdate.map(brand => ({
        ...brand,
        tags: value.map(tag => tag.value)
      }))
    });
  };

  const renderError = ({ message, error }) => (
    <ErrorContainer>
      <WarningIcon />
      {message} <p>{error.message}</p>
    </ErrorContainer>
  );

  const renderLoading = ({ message }) => (
    <>
      <StyledSpinner /> {message}
    </>
  );

  const renderSelect = () => {
    const selectTagsOptions = tags.map(tag => {
      return {
        label: tag.mn_tag_name,
        value: tag.mn_tag_id
      };
    });

    return (
      <>
        <SelectorContainer>
          Tags
          <StyledSelect
            multi={true}
            width={500}
            options={selectTagsOptions}
            value={value}
            onChange={setValue}
          />
        </SelectorContainer>
        <ButtonContainer>
          <ConfirmButton onClick={onConfirm}>Confirm</ConfirmButton>
          <CancelButton onClick={hideModal}>Cancel</CancelButton>
        </ButtonContainer>
      </>
    );
  };

  const renderConfirmation = () => {
    return (
      <>
        <p>
          The selected tags will be applied to {brandsToUpdate.length}
          brands. Do you want to continue?
        </p>
        <ButtonContainer>
          <ConfirmButton onClick={onConfirm}>Confirm</ConfirmButton>
          <CancelButton onClick={hideModal}>Cancel</CancelButton>
        </ButtonContainer>
      </>
    );
  };

  const renderContent = () => {
    if (errorTags)
      return renderError({
        message: "There was an error loading tags",
        error: renderError
      });
    if (editingError)
      return renderError({
        message: "There was an error setting the new tags",
        error: editingError
      });
    if (loadingTags) return renderLoading({ message: "Loading Tags..." });
    if (askForConfirmation) return renderConfirmation();
    if (editingBrand)
      return renderLoading({ message: "Setting the new tags..." });
    return renderSelect();
  };

  return <Container>{renderContent()}</Container>;
};
