// Control implemented as ES6 class
function createControlButton({ className, onClick, title }) {
  const button = document.createElement("button");
  button.className = className;
  button.setAttribute("title", title);

  button.addEventListener(
    "click",
    e => {
      e.preventDefault();
      e.stopPropagation();

      onClick();
    },
    true
  );

  return button;
}

export default class CustomControls {
  static handlers;
  constructor(handlers) {
    this.handlers = handlers;
  }
  onAdd(map) {
    this._map = map;
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";

    const undoButton = createControlButton({
      className: "undo",
      title: "Undo",
      onClick: this.handlers.onUndo
    });
    const drawButton = createControlButton({
      className: "draw",
      title: "Draw Polygon",
      onClick: this.handlers.onDraw
    });
    const editButton = createControlButton({
      className: "edit",
      title: "Edit",
      onClick: this.handlers.onEdit
    });
    const confirmButton = createControlButton({
      className: "confirm",
      title: "Confirm Edition",
      onClick: this.handlers.onConfirm
    });
    const deleteButton = createControlButton({
      className: "delete",
      title: "Delete Polygon",
      onClick: this.handlers.onDelete
    });
    const closeButton = createControlButton({
      className: "close",
      title: "Close Map",
      onClick: this.handlers.onClose
    });

    this._container.appendChild(closeButton);
    this._container.appendChild(drawButton);
    this._container.appendChild(editButton);
    this._container.appendChild(confirmButton);
    this._container.appendChild(deleteButton);
    this._container.appendChild(undoButton);

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}
