import React, { useState } from "react";
import brandsStore from "../../store/brands";
import iabCategoriesStore from "../../store/iabCategories";
import { renderDef } from "../../utils/functions";

import {
  Container,
  ErrorContainer,
  ButtonContainer,
  ConfirmButton,
  CancelButton,
  WarningIcon,
  SelectorContainer,
  StyledSelect,
  StyledSpinner
} from "./styles";

export default props => {
  const { brand, hideModal } = props;
  const {
    data: iabCategories,
    loading: loadingIabCategories,
    error: errorIabCategories
  } = iabCategoriesStore.useData();
  const selectedBrands = brandsStore.useSelected();
  const {
    loading: editingBrand,
    status: editingStatus,
    error: editingError,
    makeRequest: editBrand
  } = brandsStore.usePutNewData();
  const [value, setValue] = useState();
  const [askForConfirmation, setAskForConfirmation] = useState(false);

  const brandsToUpdate = [...selectedBrands];
  if (!brandsToUpdate.find(sb => sb.mn_brand_id === brand.mn_brand_id))
    brandsToUpdate.push(brand);

  if (editingStatus === 200) hideModal();

  const onConfirm = () => {
    if (!askForConfirmation && brandsToUpdate.length > 1)
      return setAskForConfirmation(true);
    setAskForConfirmation(false);

    editBrand({
      data: brandsToUpdate.map(brand => ({
        ...brand,
        iab_category_id: value.value
      }))
    });
  };

  const renderError = ({ message, error }) => (
    <ErrorContainer>
      <WarningIcon />
      {message} <p>{error.message}</p>
    </ErrorContainer>
  );

  const renderLoading = ({ message }) => (
    <>
      <StyledSpinner /> {message}
    </>
  );

  const renderSelect = () => {
    const selectIabCategory = iabCategories.map(iabCateogry => {
      const { tier_1, tier_2, tier_3, tier_4 } = iabCateogry;
      return {
        label: renderDef([tier_1, tier_2, tier_3, tier_4]),
        value: iabCateogry.iab_category_id
      };
    });

    return (
      <>
        <SelectorContainer>
          Select a Iab Category
          <StyledSelect
            options={selectIabCategory}
            value={value}
            onChange={setValue}
          />
        </SelectorContainer>
        <ButtonContainer>
          <ConfirmButton onClick={onConfirm}>Confirm</ConfirmButton>
          <CancelButton onClick={hideModal}>Cancel</CancelButton>
        </ButtonContainer>
      </>
    );
  };

  const renderConfirmation = () => {
    return (
      <>
        <p>
          The selected category will be applied to {brandsToUpdate.length}{" "}
          brands. Do you want to continue?
        </p>
        <ButtonContainer>
          <ConfirmButton onClick={onConfirm}>Confirm</ConfirmButton>
          <CancelButton onClick={hideModal}>Cancel</CancelButton>
        </ButtonContainer>
      </>
    );
  };

  const renderContent = () => {
    if (errorIabCategories)
      return renderError({
        message: "There was an error loading iab categories",
        error: renderError
      });
    if (editingError)
      return renderError({
        message: "There was an error setting the new category",
        error: editingError
      });
    if (loadingIabCategories)
      return renderLoading({ message: "Loading Iab Categories..." });
    if (askForConfirmation) return renderConfirmation();
    if (editingBrand)
      return renderLoading({ message: "Setting the new category..." });
    return renderSelect();
  };

  return <Container>{renderContent()}</Container>;
};
