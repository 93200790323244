import React from "react";
import { navigate } from "@reach/router";
import {
  Container,
  Logo,
  LogoutContainer,
  LogoutIcon,
  MenuLogo
} from "./styles";
import logo from "./logo.png";
import { logOut } from "../utils/auth";

export default ({ toggleMenu }) => {
  console.log(toggleMenu);
  function onLogout() {
    logOut();
    navigate("/login");
  }

  return (
    <Container>
      <MenuLogo onClick={toggleMenu} />
      <Logo src={logo} />
      <LogoutContainer onClick={onLogout}>
        <LogoutIcon />
        <p>logout</p>
      </LogoutContainer>
    </Container>
  );
};
