import React, { useState, useEffect } from "react";
import brandsStore from "../../store/brands";
import iabCategoriesStore from "../../store/iabCategories";
import naicsStore from "../../store/naics";
import segmentsStore from "../../store/segments";
import tagsStore from "../../store/tags";
import { renderDef } from "../../utils/functions";
import {
  Container,
  FormContainer,
  ErrorContainer,
  ButtonContainer,
  ConfirmButton,
  CancelButton,
  LoadingContainer,
  WarningIcon,
  SelectorContainer,
  StyledSelect,
  BrandNameInput,
  StyledSpinner
  //ExistingBrands
} from "./styles";

export default props => {
  const { hideModal } = props;
  const {
    data: brands,
    loading: loadingBrands,
    error: errorBrands
  } = brandsStore.useData();
  const { data: tags } = tagsStore.useData();
  const {
    data: iabCategories,
    loading: loadingIabCategories,
    error: errorIabCategories
  } = iabCategoriesStore.useData();
  const {
    data: naics,
    loading: loadingNaics,
    error: errorNaics
  } = naicsStore.useData();
  const {
    data: segments,
    loading: loadingSegments,
    error: errorSegments
  } = segmentsStore.useData();
  const {
    loading: creatingBrand,
    status: creatingStatus,
    error: creatingError,
    makeRequest: createBrand
  } = brandsStore.usePostNewData();
  const findBrands = brandsStore.useQuery();

  const [currentBrandName, setCurrentBrandName] = useState("");
  const [formError, setFormError] = useState();
  const [currentParent, setCurrentParent] = useState();
  const [currentIabCategory, setCurrentIabCategory] = useState();
  const [currentNaics, setCurrentNaics] = useState();
  const [currentSegment, setCurrentSegment] = useState();
  const [currentTags, setCurrentTags] = useState();
  const [saveAndNew, setSaveAndNew] = useState();

  const reset = () => {
    setCurrentBrandName("");
    setCurrentParent(null);
    setCurrentIabCategory(null);
    setCurrentNaics(null);
    setCurrentSegment(null);
  };

  useEffect(() => {
    if (creatingStatus === 200) {
      if (!saveAndNew) return hideModal();
      reset();
    }
  }, [creatingStatus]);

  const create = () => {
    createBrand({
      data: [
        {
          brand_name: currentBrandName,
          parent_brand_id: currentParent && currentParent.value,
          iab_category_id: currentIabCategory && currentIabCategory.value,
          naics_code: currentNaics && currentNaics.value,
          mn_segment_id: currentSegment.value,
          tags: currentTags ? currentTags.map(tag => tag.value) : []
        }
      ]
    });
  };

  const onBrandNameChange = event => {
    const value = event && event.target && event.target.value;
    setCurrentBrandName(value);
  };

  const validateForm = () => {
    if (!currentBrandName) {
      setFormError("The brand name is required");
      return false;
    }
    if (!currentSegment) {
      setFormError("The segment is required");
      return false;
    }

    const brands = findBrands({
      field: "brand_name",
      value: currentBrandName
    });
    if (brands && brands.length > 0) {
      setFormError("The brand already exists");
      return false;
    }

    setFormError(null);

    return true;
  };

  const onSaveAndNew = () => {
    if (!validateForm()) return;
    setSaveAndNew(true);
    create();
  };
  const onSaveAndClose = () => {
    if (!validateForm()) return;
    setSaveAndNew(false);
    create();
  };

  const renderError = ({ message, error }) => (
    <ErrorContainer>
      <WarningIcon />
      {message} <p>{error.message}</p>
    </ErrorContainer>
  );

  const renderLoading = ({ message }) => (
    <LoadingContainer>
      <StyledSpinner /> {message}
    </LoadingContainer>
  );

  const renderSelect = () => {
    const selectParentOptions = brands.map(brand => {
      return {
        label: brand.brand_name,
        value: brand.mn_brand_id
      };
    });
    const selectSegmentOptions = segments.map(segment => {
      const { tier_1, tier_2, tier_3, tier_4 } = segment;
      return {
        label: renderDef([tier_1, tier_2, tier_3, tier_4]),
        value: segment.mn_segment_id
      };
    });
    const selectIabCategory = iabCategories.map(iabCateogry => {
      const { tier_1, tier_2, tier_3, tier_4 } = iabCateogry;
      return {
        label: renderDef([tier_1, tier_2, tier_3, tier_4]),
        value: iabCateogry.iab_category_id
      };
    });
    const selectNaicsOptions = naics.map(naics => {
      const {
        naics_us_title,
        naics_code,
        tier_1,
        tier_2,
        tier_3,
        tier_4
      } = naics;
      return {
        label: renderDef([
          naics_us_title,
          naics_code,
          tier_1,
          tier_2,
          tier_3,
          tier_4
        ]),
        value: naics.naics_code
      };
    });
    const selectTagsOptions = tags.map(tag => {
      return {
        label: tag.mn_tag_name,
        value: tag.mn_tag_id
      };
    });

    return (
      <>
        {/*currentBrands.length > 0 && (
          <ExistingBrands>
            {currentBrands.map(brand => (
              <p>{brand.brand_name}</p>
            ))}
          </ExistingBrands>
        )*/}
        <FormContainer>
          {formError && (
            <ErrorContainer>
              <p>{formError}</p>
            </ErrorContainer>
          )}
          Brand Name
          <BrandNameInput
            value={currentBrandName}
            onChange={onBrandNameChange}
          />
          <SelectorContainer>
            Parent Brand
            <StyledSelect
              options={selectParentOptions}
              value={currentParent}
              onChange={setCurrentParent}
            />
          </SelectorContainer>
          <SelectorContainer>
            IAB Category
            <StyledSelect
              width={500}
              options={selectIabCategory}
              value={currentIabCategory}
              onChange={setCurrentIabCategory}
            />
          </SelectorContainer>
          <SelectorContainer>
            Naics Code
            <StyledSelect
              width={500}
              options={selectNaicsOptions}
              value={currentNaics}
              onChange={setCurrentNaics}
            />
          </SelectorContainer>
          <SelectorContainer>
            MN Segment
            <StyledSelect
              width={500}
              options={selectSegmentOptions}
              value={currentSegment}
              onChange={setCurrentSegment}
            />
          </SelectorContainer>
          <SelectorContainer>
            Tags
            <StyledSelect
              multi={true}
              width={500}
              options={selectTagsOptions}
              value={currentTags}
              onChange={setCurrentTags}
            />
          </SelectorContainer>
          <ButtonContainer>
            <ConfirmButton onClick={onSaveAndNew}>Save & New</ConfirmButton>
            <ConfirmButton onClick={onSaveAndClose}>Save & Close</ConfirmButton>
            <CancelButton onClick={hideModal}>Cancel</CancelButton>
          </ButtonContainer>
        </FormContainer>
      </>
    );
  };

  const renderContent = () => {
    if (errorBrands || errorIabCategories || errorNaics || errorSegments)
      return renderError({
        message: "There was an error loading data",
        error: errorBrands || errorIabCategories || errorNaics || errorSegments
      });
    if (creatingError)
      return renderError({
        message: "There was an error creating the new brand",
        error: creatingError
      });
    if (
      loadingBrands ||
      loadingIabCategories ||
      loadingNaics ||
      loadingSegments
    )
      return renderLoading({ message: "Loading ..." });
    if (creatingBrand)
      return renderLoading({ message: "Creating new brand..." });
    return renderSelect();
  };

  return <Container>{renderContent()}</Container>;
};
