import React, { useState } from "react";
import {
  Container,
  ErrorContainer,
  ButtonContainer,
  ConfirmButton,
  CancelButton,
  WarningIcon,
  SuccessContainer,
  StyledSpinner,
  StyledSelect,
  SelectorContainer,
  StyledTick
} from "./styles";
import { useTimeout } from "../../utils/hooks";
import sourcesStore from "../../store/sources";
import importPlacesStore from "../../store/importPlaces";

export default props => {
  const [slowUpload] = useTimeout(200);
  const [source, setSource] = useState();

  const {
    makeRequest: insertAndValidate,
    loading: insertLoading,
    error: insertError,
    response: insertResponse
  } = importPlacesStore.usePostNewData();
  const { data: sources, error: errorSources } = sourcesStore.useData();
  const { hideModal, uploadProgress, uploadError, response } = props;

  if (insertResponse) hideModal();

  const onConfirm = () => {
    insertAndValidate({
      data: response,
      query: {
        mn_source_id: source.value
      }
    });
  };

  const renderProgress = () => (
    <>
      <StyledSpinner /> Uploading File...
      {uploadProgress > 0 && uploadProgress + "%"}
    </>
  );
  const renderUploadError = () => (
    <ErrorContainer>
      <WarningIcon />
      There was an error processing the file. <p>{uploadError.message}</p>
    </ErrorContainer>
  );

  const renderSourcesError = () => (
    <ErrorContainer>
      <WarningIcon />
      There was an error. <p>{errorSources.message}</p>
    </ErrorContainer>
  );

  const renderInsertError = () => (
    <ErrorContainer>
      <WarningIcon />
      There was an error validating the new places. <p>{insertError}</p>
    </ErrorContainer>
  );

  const renderCompleted = () => (
    <>
      <StyledSpinner /> File Uploaded. Parsing new places...
    </>
  );

  const renderValidating = () => (
    <>
      <StyledSpinner /> Importing and validating new places...
    </>
  );

  const renderSuccess = () => {
    const selectOptions = sources.map(source => {
      return {
        label: source.mn_source_name,
        value: source.mn_source_id
      };
    });
    return (
      <SuccessContainer>
        <StyledTick /> File successfuly parsed.
        <p>
          <b>{response.length}</b> new{" "}
          {response.length === 1 ? "place" : "places"} found. To import them
          select a source and click Confirm.
        </p>
        <SelectorContainer>
          Select a Source
          <StyledSelect
            options={selectOptions}
            value={source}
            onChange={setSource}
          />
        </SelectorContainer>
        <ButtonContainer>
          {source && <ConfirmButton onClick={onConfirm}>Confirm</ConfirmButton>}
          <CancelButton onClick={hideModal}>Cancel</CancelButton>
        </ButtonContainer>
      </SuccessContainer>
    );
  };

  const renderContent = () => {
    if (uploadError) return renderUploadError();
    if (errorSources) return renderSourcesError();
    if (insertError) return renderInsertError();
    if (insertLoading) return renderValidating();
    if (slowUpload && uploadProgress < 100) return renderProgress();
    if (slowUpload && uploadProgress === 100 && !response)
      return renderCompleted();
    if (response && response.length > 0) return renderSuccess();
  };

  return <Container>{renderContent()}</Container>;
};
