import { MapMarkedAlt } from "styled-icons/fa-solid/MapMarkedAlt";
import { Tick } from "styled-icons/typicons/Tick";
import { Warning } from "styled-icons/typicons/Warning";
import { ButtonSuccess } from "../../utils/styles";

import styled from "styled-components";

export const MapIcon = styled(MapMarkedAlt)`
  width: 15px;
  height: 15px;
  color: ${props => {
    return props.selected && props.theme.blue;
  }};
  :hover {
    cursor: pointer;
  }
`;

export const TickIcon = styled(Tick)`
  width: 15px;
  height: 15px;
  color: ${props => props.theme.blue};
  :hover {
    cursor: pointer;
  }
`;

export const WarningIcon = styled(Warning)`
  width: 15px;
  height: 15px;
  color: ${props => props.theme.red};
  :hover {
    cursor: pointer;
  }
`;

export const GeocodeButton = styled(ButtonSuccess)`
  ${props => props.disabled && "background:" + props.theme.grey}
  ${props => props.disabled && "border-color:" + props.theme.grey}
  :hover {
    cursor: ${props => (props.disabled ? "auto" : "pointer")};
  }
`;
