import styled from "styled-components";
import { Spinner } from "../../utils/styles";
import { Warning } from "styled-icons/typicons/Warning";
import { ButtonSuccess, ButtonDanger } from "../../utils/styles";

export const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const WarningIcon = styled(Warning)`
  width: 40px;
  height: 40px;
  margin-bottom: 2px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${props => props.theme.red};
`;

export const ConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const ConfirmButton = styled(ButtonSuccess)`
  margin-right: 3px;
`;
export const CancelButton = styled(ButtonDanger)``;

export const StyledSpinner = styled(Spinner)`
  width: 40px;
  height: 40px;
`;
