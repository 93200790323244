import styled from "styled-components";

import {
  TableActions as CommonTableActions,
  Container as CommonTableContainer
} from "../styles";
import { ButtonSuccess } from "../../utils/styles";

export const TableActions = styled(CommonTableActions)`
  margin-bottom: 5px;
`;

export const ImportButtonContainer = styled.div`
  overflow: hidden;
  position: relative;
  display: inline-block;

  > input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    :hover {
      cursor: pointer;
    }
  }
`;

export const ImportButton = styled(ButtonSuccess)`
  margin-right: 5px;
  padding: 5px;
`;

export const Container = styled(CommonTableContainer)`
  margin-bottom: 20px;
`;
