import { useEffect } from "react";
import { createDataStore } from "./common";
import importPlacesStore from "./importPlaces";
import { useRequest } from "../utils/request";
import brandsStore from "./brands";
import sourcesStore from "./sources";

const stagingPlacesStore = createDataStore({
  storeKey: "stagingPlaces",
  filterField: "mn_chain_brand_name",
  endpoint: process.env.REACT_APP_PLACES_API_HOST + "/stagingPlaces",
  deleteEndpoint: process.env.REACT_APP_PLACES_API_HOST + "/deleteStagingPlaces"
});

export const useData = () => {
  const {
    loading: loadingBrands,
    error: errorBrands,
    entities: brands
  } = brandsStore.useEntities();
  const {
    loading: loadingSources,
    error: errorSources,
    entities: sources
  } = sourcesStore.useEntities();
  const { data, loading, error, ...rest } = stagingPlacesStore.useData();

  let populatedData;
  populatedData = data.map(place => ({
    ...place,
    distributed: place.distributed
      ? place.distributed.map(brandId => ({
          mn_chain_brand_id: brands[brandId] && brands[brandId].mn_brand_id,
          mn_chain_brand_name: brands[brandId] && brands[brandId].brand_name
        }))
      : [],
    mn_chain_brand_name: place.mn_chain_brand_id
      ? brands[place.mn_chain_brand_id] &&
        brands[place.mn_chain_brand_id].brand_name
      : place.mn_chain_brand_name,
    mn_source_name:
      sources[place.mn_source_id] && sources[place.mn_source_id].mn_source_name
  }));

  return {
    data: populatedData,
    loading: loading || loadingBrands || loadingSources,
    error: error || errorBrands || errorSources,
    ...rest
  };
};

export const useDeleteSelected = () => {
  const selectedStagingPlaces = stagingPlacesStore.useSelected();
  const deleteStagingPlaces = stagingPlacesStore.useDeleteData();
  const {
    makeRequest: deleteImportPlaces,
    error,
    loading,
    status
  } = importPlacesStore.useDelete();

  const makeRequest = () => {
    deleteImportPlaces({
      data: selectedStagingPlaces.map(sp => sp.mn_import_id)
    });
  };

  useEffect(() => {
    if (status === 200)
      deleteStagingPlaces({ data: selectedStagingPlaces.map(sp => sp.id) });
  }, [status]);

  return { makeRequest, status, loading, error };
};

export const useResetSelected = () => {
  const selectedStagingPlaces = stagingPlacesStore.useSelected();
  const updateStagingPlaces = stagingPlacesStore.useUpdateData();
  const { makeRequest, status, response, ...rest } = useRequest();

  const resetSelected = () => {
    makeRequest({
      endpoint: process.env.REACT_APP_PLACES_API_HOST + "/resetPlaces",
      method: "PUT",
      data: selectedStagingPlaces
    });
  };

  useEffect(() => {
    if (status === 200) updateStagingPlaces({ data: response });
  }, [status]);

  return { resetSelected, status, ...rest };
};

export const useValidator = () => {
  const { makeRequest, ...rest } = useRequest();

  const validatePlaces = ({ places }) => {
    makeRequest({
      endpoint: process.env.REACT_APP_PLACES_API_HOST + "/validatePlaces",
      method: "POST",
      data: places
    });
  };

  return { validatePlaces, ...rest };
};

export const useUpdateLatLng = () => {
  const { makeRequest, ...rest } = stagingPlacesStore.usePutNewData();
  const { entities } = stagingPlacesStore.useEntities();

  const updateLatLng = ({ latitude, longitude, id }) => {
    makeRequest({ data: [{ ...entities[id], latitude, longitude }] });
  };

  return { updateLatLng, ...rest };
};

export const useGeocoder = () => {
  const { makeRequest, status, response, ...rest } = useRequest();
  const updateData = stagingPlacesStore.useUpdateData();

  const geocode = ({ places, query }) => {
    const data = places.map(place => {
      const elem = { ...place };
      delete elem.isSelected;
      return elem;
    });
    makeRequest({
      endpoint: process.env.REACT_APP_PLACES_API_HOST + "/geocode",
      query,
      method: "PUT",
      data
    });
  };

  useEffect(() => {
    if (status === 200) {
      updateData({ data: response });
    }
  }, [status]);

  return { geocode, status, response, ...rest };
};

export const useDeploy = () => {
  const { makeRequest, status, response, ...rest } = useRequest();
  const deleteStagingData = stagingPlacesStore.useDeleteData();
  const deleteImportData = importPlacesStore.useDeleteData();

  const deploy = ({ places, query }) => {
    makeRequest({
      endpoint: process.env.REACT_APP_PLACES_API_HOST + "/deploy",
      query,
      method: "PUT",
      data: places
    });
  };

  useEffect(() => {
    if (status === 200) {
      deleteStagingData({ data: response.map(place => place.id) });
      deleteImportData({ data: response.map(place => place.mn_import_id) });
    }
  }, [status]);

  return { deploy, status, response, ...rest };
};

export default {
  ...stagingPlacesStore,
  useUpdateLatLng,
  useData,
  useDeleteSelected,
  useValidator,
  useResetSelected,
  useGeocoder,
  useDeploy
};
