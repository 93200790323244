import styled from "styled-components";
import { SignOutAlt } from "styled-icons/fa-solid/SignOutAlt";
import { MenuAltLeft } from "styled-icons/boxicons-regular/MenuAltLeft";

export const Container = styled.div`
  color: ${props => props.theme.darkGrey};
  grid-area: header;
  display: flex;
  z-index: 2;
  justify-content: space-between;
  align-items: center;
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.5);
  padding: 10px 15px 10px 15px;
  background-color: ${props => props.theme.lightGrey};
`;

export const Logo = styled.img`
  max-height: 65px;
  max-width: 150px;
  width: auto;
  height: auto;
`;

export const LogoutContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  :hover {
    cursor: pointer;
  }
`;

export const LogoutIcon = styled(SignOutAlt)`
  width: 15px;
  height: 15px;
  margin-right: 3px;
`;

export const MenuLogo = styled(MenuAltLeft)`
  width: 25px;
  height: 25px;
  :hover {
    cursor: pointer;
  }
`;
