import React from "react";
import { format } from "date-fns";
import importPlacesStore from "../../store/importPlaces";
import stagingPlacesStore from "../../store/stagingPlaces";
import { useUploadFile } from "../../utils/fileUpload";
import { useModal } from "../../utils/hooks";
import { Spinner } from "../../utils/styles";
import {
  DeleteButton,
  MessageContainer,
  ActionButtonsContainer
} from "../styles";
import {
  TableActions,
  ImportButton,
  ImportButtonContainer,
  Container
} from "./styles";

import UploadProgress from "../../modals/UploadProgress/UploadProgress";
import Table from "../Table/Table";
import Search from "../Search/Search";

export default ({ height }) => {
  const {
    data,
    error,
    loading,
    sortField,
    sortDirection,
    firstLoad
  } = importPlacesStore.useData();
  const { numElemsSelected } = stagingPlacesStore.useData();
  const sort = importPlacesStore.useSort();
  const {
    currentFilter,
    filterField,
    setCurrentFilter,
    setFilterField
  } = importPlacesStore.useFilter();
  const togglePlace = importPlacesStore.useToggleElement();
  const toggleAllPlaces = importPlacesStore.useToggleAllElements();
  const { uploadFile, response, uploadProgress, uploadError } = useUploadFile();
  const [showUploadProgressModal] = useModal(
    () => (
      <UploadProgress
        response={response}
        uploadProgress={uploadProgress}
        uploadError={uploadError}
      />
    ),
    {
      variables: [uploadProgress, uploadError, response]
    }
  );

  const fields = [
    { name: "mn_chain_brand_name", width: 150, label: "Brand" },
    { name: "place_name", width: 150, label: "Place Name" },
    { name: "street_name", width: 200, label: "Street" },
    { name: "street_number", width: 80, label: "St Num" },
    {
      name: "full_address",
      width: 200,
      label: "Full Address"
    },
    { name: "postal_code", width: 100, label: "Zipcode" },
    { name: "city_name", width: 150, label: "City" },
    { name: "region_code", width: 100, label: "Region" },
    { name: "country_code", width: 80, label: "Country" },
    {
      name: "latitude",
      width: 100,
      label: "Lat",
      format: val => val && val.substring(0, 10)
    },
    {
      name: "longitude",
      width: 100,
      label: "Lng",
      format: val => val && val.substring(0, 10)
    },
    {
      name: "created_at",
      width: 100,
      label: "Created At",
      format: value => value && format(new Date(value), "YYYY-MM-DD")
    }
  ];

  const onFilterChange = e => {
    const value = e.target.value;
    setCurrentFilter({ value });
  };

  const onFilterFieldChange = e => {
    const value = e.target.value;
    setFilterField({ field: value });
  };

  const handleUploadFile = e => {
    const file = e.target.files[0];
    uploadFile({
      file,
      endpoint: process.env.REACT_APP_PLACES_API_HOST + "/uploadExcel"
    });
    showUploadProgressModal();
    e.target.value = null;
  };

  const onSelectAll = allRowsSelected => {
    toggleAllPlaces({ selected: allRowsSelected });
  };

  const onSelectPlace = place => {
    togglePlace({ id: place.mn_import_id });
  };

  const renderMessage = () => {
    if (error)
      return <span>There was an error loading places. {error.message}</span>;
    if (loading)
      return (
        <span>
          <Spinner /> Loading places...
        </span>
      );
    if (numElemsSelected)
      return (
        <span>
          {numElemsSelected} {numElemsSelected === 1 ? "place" : "places"}{" "}
          selected
        </span>
      );
    return "Imported Places";
  };

  return (
    <Container>
      <TableActions>
        {firstLoad && (
          <ActionButtonsContainer>
            <ImportButtonContainer>
              <input
                type="file"
                accept=".xls,.xlsx"
                onChange={handleUploadFile}
              />
              <ImportButton> Import Excel </ImportButton>
            </ImportButtonContainer>
          </ActionButtonsContainer>
        )}
        <MessageContainer>{renderMessage()}</MessageContainer>
        {firstLoad && (
          <Search
            currentFilter={currentFilter}
            filterField={filterField}
            onFilterChange={onFilterChange}
            onFilterFieldChange={onFilterFieldChange}
            fields={fields}
          />
        )}
      </TableActions>
      <Table
        height={height}
        fields={fields}
        sortField={sortField}
        sortDirection={sortDirection}
        data={data}
        onSort={sort}
        onSelectAll={onSelectAll}
        onSelectRow={onSelectPlace}
      />
    </Container>
  );
};
