import { createDataStore } from "./common";

import iabCategoriesStore from "./iabCategories";
import naicsStore from "./naics";
import segmentsStore from "./segments";
import tagsStore from "./tags";
import { renderDef } from "../utils/functions";

const brandsStore = createDataStore({
  storeKey: "brands",
  endpoint: process.env.REACT_APP_PLACES_API_HOST + "/brands",
  idKey: "mn_brand_id",
  filterField: "brand_name"
});

export const usePutNewData = () => {
  const { makeRequest, ...rest } = brandsStore.usePutNewData();

  const putRequest = ({ data }) => {
    data.forEach(brand => {
      delete brand.iab_category_name;
      delete brand.naics_name;
      delete brand.segment_name;
      delete brand.parent_brand_name;
    });

    makeRequest({ data });
  };

  return { makeRequest: putRequest, ...rest };
};

export const useData = () => {
  const {
    loading: loadingIabCategories,
    error: errorIabCategories,
    entities: iabCategories,
    loaded: iabLoaded
  } = iabCategoriesStore.useEntities();
  const {
    loading: loadingNaics,
    error: errorNaics,
    entities: naics,
    loaded: naicsLoaded
  } = naicsStore.useEntities();
  const {
    loading: loadingTags,
    error: errorTags,
    entities: tags,
    loaded: tagsLoaded
  } = tagsStore.useEntities();
  const {
    loading: loadingSegments,
    error: errorSegments,
    entities: segments,
    loaded: segmentsLoaded
  } = segmentsStore.useEntities();

  const { data, loading, error, loaded, ...rest } = brandsStore.useData();
  const { entities } = brandsStore.useEntities();

  // console.log(iabLoaded, naicsLoaded, segmentsLoaded, tagsLoaded, loaded);

  const allLoaded =
    iabLoaded && naicsLoaded && segmentsLoaded && tagsLoaded && loaded;

  let populatedData;
  populatedData = !allLoaded
    ? []
    : data.map(brand => {
        let iab_category_name;
        let naics_name;
        let segment_name;
        let parent_brand_name;

        const iabCategory =
          brand.iab_category_id && iabCategories[brand.iab_category_id];
        if (iabCategory) {
          const { tier_1, tier_2, tier_3, tier_4 } = iabCategory;
          iab_category_name = renderDef([tier_1, tier_2, tier_3, tier_4]);
        }

        const naic = brand.naics_code && naics[brand.naics_code];
        if (naic) {
          const { naics_code, tier_1, tier_2, tier_3, tier_4 } = naic;
          naics_name = renderDef([naics_code, tier_1, tier_2, tier_3, tier_4]);
        }

        const segment = brand.mn_segment_id && segments[brand.mn_segment_id];
        if (segment) {
          const { tier_1, tier_2, tier_3, tier_4 } = segment;
          segment_name = renderDef([tier_1, tier_2, tier_3, tier_4]);
        }

        const parent = brand.parent_brand_id && entities[brand.parent_brand_id];
        if (parent) {
          parent_brand_name = parent.brand_name;
        }

        const brandTags = [];
        if (brand.tags && brand.tags.length > 0) {
          brand.tags.forEach(tagId => {
            if (tags[tagId]) brandTags.push(tags[tagId]);
          });
        }

        return {
          ...brand,
          tags: brandTags,
          iab_category_name,
          naics_name,
          segment_name,
          parent_brand_name
        };
      });

  return {
    data: populatedData,
    loading:
      loading ||
      loadingIabCategories ||
      loadingNaics ||
      loadingSegments ||
      loadingTags,
    error:
      error || errorIabCategories || errorNaics || errorSegments || errorTags,
    ...rest
  };
};

export default {
  ...brandsStore,
  usePutNewData,
  useData
};
