import React, { useState } from "react";
import countriesStore from "../../store/countries";
import stagingPlacesStore from "../../store/stagingPlaces";
import {
  Container,
  ErrorContainer,
  ButtonContainer,
  ConfirmButton,
  CancelButton,
  WarningIcon,
  SelectorContainer,
  StyledSelect,
  StyledSpinner
} from "./styles";

export default props => {
  const { place, hideModal } = props;
  const {
    data: countries,
    loading: loadingCountries,
    error: errorCountries
  } = countriesStore.useData();
  const selectedPlaces = stagingPlacesStore.useSelected();
  const {
    loading: editingPlace,
    status: editingStatus,
    error: editingError,
    makeRequest: editPlace
  } = stagingPlacesStore.usePutNewData();
  const [value, setValue] = useState();
  const [askForConfirmation, setAskForConfirmation] = useState(false);

  const placesToUpdate = [...selectedPlaces];
  if (place && !placesToUpdate.find(sp => sp.id === place.id))
    placesToUpdate.push(place);

  if (editingStatus === 200) hideModal();

  const onConfirm = () => {
    if (!askForConfirmation && placesToUpdate.length > 1)
      return setAskForConfirmation(true);
    setAskForConfirmation(false);

    editPlace({
      data: placesToUpdate.map(place => ({
        ...place,
        country_code: value.value
      }))
    });
  };

  const renderError = ({ message, error }) => (
    <ErrorContainer>
      <WarningIcon />
      {message} <p>{error.message}</p>
    </ErrorContainer>
  );

  const renderLoading = ({ message }) => (
    <>
      <StyledSpinner /> {message}
    </>
  );

  const renderSelect = () => {
    const selectOptions = countries.map(country => {
      return {
        label: country.name,
        value: country.code
      };
    });

    return (
      <>
        <SelectorContainer>
          Select a Country
          <StyledSelect
            options={selectOptions}
            value={value}
            onChange={setValue}
          />
        </SelectorContainer>
        <ButtonContainer>
          <ConfirmButton onClick={onConfirm}>Confirm</ConfirmButton>
          <CancelButton onClick={hideModal}>Cancel</CancelButton>
        </ButtonContainer>
      </>
    );
  };

  const renderConfirmation = () => {
    return (
      <>
        <p>
          The selected country will be applied to {placesToUpdate.length}{" "}
          places. Do you want to continue?
        </p>
        <ButtonContainer>
          <ConfirmButton onClick={onConfirm}>Confirm</ConfirmButton>
          <CancelButton onClick={hideModal}>Cancel</CancelButton>
        </ButtonContainer>
      </>
    );
  };

  const renderContent = () => {
    if (errorCountries)
      return renderError({
        message: "There was an error loading countries",
        error: errorCountries
      });
    if (editingError)
      return renderError({
        message: "There was an error setting the new country",
        error: editingError
      });
    if (loadingCountries)
      return renderLoading({ message: "Loading Countries..." });
    if (askForConfirmation) return renderConfirmation();
    if (editingPlace)
      return renderLoading({ message: "Setting the new country..." });
    return renderSelect();
  };

  return <Container>{renderContent()}</Container>;
};
