import React, { useState } from "react";
import useWindowSize from "@rehooks/window-size";
import stagingPlacesStore from "../store/stagingPlaces";
import footprintsStore from "../store/footprints";

import ImportTable from "../tables/ImportTable/ImportTable";
import StagingTable from "../tables/StagingTable/StagingTable";
import Map from "../Map/Map";
import { StyledSpinner, MessageContainer } from "./styles";

export default () => {
  const [currentMapPlace, setCurrentMapPlace] = useState();
  const { entities } = stagingPlacesStore.useEntities();
  const {
    updateLatLng,
    loading: loadingUpdatePlaces
  } = stagingPlacesStore.useUpdateLatLng();

  const {
    makeRequest: createFootprint,
    loading: loadingNewFootprint
  } = footprintsStore.usePostNewData();
  const {
    makeRequest: updateFootprint,
    loading: loadingEditFootprint
  } = footprintsStore.usePutNewData();
  const {
    makeRequest: deleteFootprint,
    loading: loadingDeleteFootprint
  } = footprintsStore.useDelete();

  const loading =
    loadingNewFootprint ||
    loadingEditFootprint ||
    loadingDeleteFootprint ||
    loadingUpdatePlaces;

  const { innerHeight } = useWindowSize();

  const height = (innerHeight - 200) / 2;

  const onMapClick = place => {
    setCurrentMapPlace(place);
  };

  const onMapClose = place => {
    setCurrentMapPlace(null);
  };

  return (
    <>
      {loading && (
        <MessageContainer>
          <StyledSpinner /> Updating Map. Please Wait...
        </MessageContainer>
      )}
      {currentMapPlace ? (
        <Map
          place={currentMapPlace}
          loading={loading}
          places={entities}
          updateLatLng={updateLatLng}
          createFootprint={createFootprint}
          updateFootprint={updateFootprint}
          deleteFootprint={deleteFootprint}
          onMapClose={onMapClose}
          height={height}
          onMapClick={onMapClick}
        />
      ) : (
        <ImportTable height={height} />
      )}
      <StagingTable
        onMapClick={onMapClick}
        currentMapPlace={currentMapPlace}
        height={height}
      />
    </>
  );
};
