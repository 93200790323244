import React, { useContext, useMemo, createContext } from "react";
import { useImmer } from "use-immer";

import importPlacesStore from "./importPlaces";
import staginPlacesStore from "./stagingPlaces";
import brandsStore from "./brands";
import iabCategoriesStore from "./iabCategories";
import naicsStore from "./naics";
import countriesStore from "./countries";
import segmentsStore from "./segments";
import tagsStore from "./tags";
import sourcesStore from "./sources";
import footprintsStore from "./footprints";

const makeStore = () => {
  const context = createContext();

  const StoreProvider = ({ children, initialState = {} }) => {
    const [state, setState] = useImmer(initialState);
    const contextValue = useMemo(() => [state, setState], [state]);
    return (
      <context.Provider value={contextValue}> {children} </context.Provider>
    );
  };

  const useStore = () => useContext(context);

  return {
    StoreProvider,
    useStore
  };
};

const initialState = {
  importPlaces: importPlacesStore.initState,
  stagingPlaces: staginPlacesStore.initState,
  brands: brandsStore.initState,
  segments: segmentsStore.initState,
  naics: naicsStore.initState,
  iabCategories: iabCategoriesStore.initState,
  tags: tagsStore.initState,
  sources: sourcesStore.initState,
  countries: countriesStore.initState,
  footprints: footprintsStore.initState
};

const { StoreProvider, useStore } = makeStore();

export { initialState, StoreProvider, useStore };
