import React, { useState, useEffect, useRef } from "react";
import { token } from "../utils/auth";
import CustomControls from "./control";
import ReactMapboxGl, { Layer, Source } from "react-mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { NavigationControl, Popup, Marker } from "mapbox-gl";
import geojsonArea from "geojson-area";
import { Container } from "./styles";
import marker from "./marker.png";

import "./styles.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
  transformRequest: (url, resourceType) => {
    if (url.startsWith(process.env.REACT_APP_TILES_SERVER)) {
      return {
        url,
        headers: {
          Authorization: token
        }
      };
    }
  }
});

const Draw = new MapboxDraw({
  displayControlsDefault: false,
  controls: false
});

export default React.memo(
  ({
    place,
    loading,
    center,
    onMapClick,
    onMapClose,
    height,
    places,
    updateLatLng,
    createFootprint,
    updateFootprint,
    deleteFootprint
  }) => {
    const mapbox = useRef();
    const popup = useRef();
    const actions = useRef([]);
    const polygonsBkp = useRef({});
    const currentEditPolygon = useRef();
    const [initialCenter, setInitialCenter] = useState([
      place.longitude,
      place.latitude
    ]);

    useEffect(() => {
      setInitialCenter([place.longitude, place.latitude]);
    }, [place]);

    const greenMarkerImg = new Image(27, 41);
    greenMarkerImg.src = marker;

    const toggleDraggingLayers = () => {
      const staginPlacesVisibility = mapbox.current.getLayoutProperty(
        "staging_places_layer",
        "visibility"
      );

      if (staginPlacesVisibility === "visible") {
        mapbox.current.setLayoutProperty(
          "staging_places_layer",
          "visibility",
          "none"
        );
        mapbox.current.setLayoutProperty(
          "staging_places_multiple_count_layer",
          "visibility",
          "none"
        );
        mapbox.current.setLayoutProperty(
          "staging_places_multiple_layer",
          "visibility",
          "none"
        );
      } else {
        mapbox.current.setLayoutProperty(
          "staging_places_layer",
          "visibility",
          "visible"
        );
        mapbox.current.setLayoutProperty(
          "staging_places_multiple_count_layer",
          "visibility",
          "visible"
        );
        mapbox.current.setLayoutProperty(
          "staging_places_multiple_layer",
          "visibility",
          "visible"
        );
      }
    };
    const onMoveClick = elem => {
      const placeId = parseInt(elem.dataset.id);
      const initialLat = parseFloat(elem.dataset.lat);
      const initialLng = parseFloat(elem.dataset.lng);
      var marker = new Marker({
        draggable: true
      })
        .setLngLat([initialLng, initialLat])
        .addTo(mapbox.current);
      actions.current.push({
        type: "drag",
        marker
      });
      toggleDraggingLayers();
      const onEscPress = e => {
        if (e.keyCode === 27) {
          //esc key
          marker.remove();
          document.removeEventListener("keydown", onEscPress);
          toggleDraggingLayers();
        }
      };
      document.addEventListener("keydown", onEscPress);
      marker.on("dragend", () => {
        const { lat, lng } = marker.getLngLat();
        actions.current.push({
          type: "move",
          placeId,
          lat: initialLat,
          lng: initialLng
        });
        updateLatLng({
          id: placeId,
          latitude: lat,
          longitude: lng
        });
        document.removeEventListener("keydown", onEscPress);
        toggleDraggingLayers();
        marker.remove();
      });
      popup.current.remove();
    };

    const renderInfoPopup = ({ places }) => {
      const renderPlace = place => `
      <div class="place-info" data-id=${place.id} data-lat=${
        place.latitude
      } data-lng=${place.longitude}>
        <b class="place-name">${place.mn_chain_brand_name ||
          place.place_name} </b>
        <p class="place-address">${place.full_address} </p>
      </div>
    `;

      const html = places.reduce((html, place) => {
        return (html += renderPlace(place));
      }, "");

      return `<div class="place-popup">${html}</div>`;
    };

    const updatePolygon = e => {
      const polygon = e.features[0].geometry;
      const footprintId = e.features[0].properties.footprintId;

      if (footprintId) {
        currentEditPolygon.current = {
          footprintId,
          polygon
        };
      } else {
        createFootprint({ data: [polygon] });
        Draw.deleteAll();
      }
    };

    return (
      <Container height={height}>
        <Map
          style="mapbox://styles/mapbox/streets-v9"
          onStyleLoad={map => {
            mapbox.current = map;
            popup.current = new Popup({
              closeOnClick: false,
              offset: {
                bottom: [0, -30]
              }
            });
            popup.current.on("open", () => {
              const links = document.getElementsByClassName("place-info");
              for (const link of links) {
                link.onclick = onMoveClick.bind(null, link);
              }
            });
            mapbox.current.addControl(
              new MapboxGeocoder({
                accessToken: process.env.REACT_APP_MAPBOX_TOKEN
              }),
              "top-left"
            );
            mapbox.current.addControl(
              new CustomControls({
                onUndo: () => {
                  if (actions.current.length > 0) {
                    const action = actions.current.pop();
                    if (action.type === "insert") {
                      createFootprint({ data: [action.data] });
                    }
                    if (action.type === "update") {
                      updateFootprint({
                        data: [
                          {
                            id: action.footprintId,
                            geometry: action.data
                          }
                        ]
                      });
                    }
                    if (action.type === "move") {
                      updateLatLng({
                        id: action.placeId,
                        latitude: action.lat,
                        longitude: action.lng
                      });
                    }
                    if (action.type === "drag") {
                      action.marker.remove();
                      toggleDraggingLayers();
                    }
                  }
                },
                onEdit: () => {
                  const featuresCollection = Draw.getAll();
                  if (featuresCollection.features.length > 0) {
                    const featureId = featuresCollection.features[0].id;
                    if (featureId)
                      Draw.changeMode("direct_select", { featureId });
                  }
                },
                onConfirm: () => {
                  if (!currentEditPolygon.current) return;
                  const { footprintId, polygon } = currentEditPolygon.current;
                  if (footprintId) {
                    actions.current.push({
                      type: "update",
                      data: polygonsBkp.current[footprintId],
                      footprintId
                    });
                    updateFootprint({
                      data: [
                        {
                          id: footprintId,
                          geometry: polygon
                        }
                      ]
                    });
                  }
                  currentEditPolygon.current = null;
                  Draw.deleteAll();
                },
                onDraw: () => {
                  Draw.deleteAll();
                  Draw.changeMode("draw_polygon");
                },
                onDelete: () => {
                  const featuresCollection = Draw.getAll();
                  if (featuresCollection.features.length > 0) {
                    const footprintId =
                      featuresCollection.features[0].properties.footprintId;
                    deleteFootprint({ data: [footprintId] });
                    actions.current.push({
                      type: "insert",
                      data: featuresCollection.features[0].geometry
                    });
                  }
                  Draw.deleteAll();
                },
                onClose: onMapClose
              }),
              "top-right"
            );
            mapbox.current.addControl(new NavigationControl(), "top-left");
            mapbox.current.addControl(Draw, "top-right");

            mapbox.current.on("draw.create", updatePolygon);
            mapbox.current.on("draw.update", updatePolygon);
          }}
          center={initialCenter}
          zoom={[18]}
          containerStyle={{
            height: "100%",
            width: "100%"
          }}
        >
          <Source
            id="footprints_microsoft"
            tileJsonSource={{
              type: "vector",
              tiles: [
                `${
                  process.env.REACT_APP_TILES_SERVER
                }/footprints_microsoft/{z}/{x}/{y}.mvt`
              ]
            }}
          />
          <Source
            id="footprints_mobiquity"
            tileJsonSource={{
              type: "vector",
              tiles: [
                `${
                  process.env.REACT_APP_TILES_SERVER
                }/footprints_mobiquity/{z}/{x}/{y}.mvt`
              ]
            }}
          />
          <Source
            id="mn_staging_places"
            tileJsonSource={{
              type: "vector",
              tiles: [
                `${
                  process.env.REACT_APP_TILES_SERVER
                }/staging_places/{z}/{x}/{y}.mvt`
              ]
            }}
          />
          <Layer
            type="fill"
            id="footprints_microsoft_layer_id"
            sourceId="footprints_microsoft"
            minZoom={14}
            sourceLayer={"polygons"}
            paint={{
              "fill-color": "rgba(30, 136, 229, 0.2)",
              "fill-outline-color": "rgba(30, 136, 229, 0.5)"
            }}
          />
          <Layer
            type="fill"
            id="footprints_mobiquity_layer_id"
            sourceId="footprints_mobiquity"
            minZoom={14}
            sourceLayer={"polygons"}
            onClick={e => {
              if (Draw.getMode() === "draw_polygon") return;
              Draw.deleteAll();
              e.features.sort((f1, f2) => {
                const area1 = geojsonArea.geometry(
                  JSON.parse(f1.properties.polygon)
                );
                const area2 = geojsonArea.geometry(
                  JSON.parse(f2.properties.polygon)
                );

                return area1 - area2;
              });

              const feature = e.features[0];

              const footprintId = feature.properties.id;
              const geometry = JSON.parse(feature.properties.polygon);
              Draw.add({
                id: footprintId,
                type: "Feature",
                properties: { footprintId },
                geometry
              });
              polygonsBkp.current[footprintId] = geometry;
              // Draw.changeMode("simple_select", { featureId: footprintId });
            }}
            onMouseEnter={() => {
              if (!Draw.getMode() === "draw_polygon")
                mapbox.current.getCanvas().style.cursor = "pointer";
            }}
            onMouseLeave={e => {
              mapbox.current.getCanvas().style.cursor = "";
            }}
            paint={{
              "fill-color": "rgba(141, 197, 114, 0.2)",
              "fill-outline-color": "rgba(141, 197, 114, 0.5)"
            }}
          />
          <Layer
            type="circle"
            id="staging_places_multiple_layer"
            sourceId="mn_staging_places"
            filter={[">", ["to-number", ["get", "total"]], 1]}
            sourceLayer={"polygons"}
            onClick={e => {
              const info = e.features[0].properties;
              const place_ids = info.place_ids
                .split("|")
                .map(id => parseInt(id));
              const placesToRender = place_ids.map(id => places[id]);

              mapbox.current.flyTo({
                center: {
                  lng: placesToRender[0].longitude,
                  lat: placesToRender[0].latitude
                },
                zoom: 18,
                speed: 0.8
              });

              popup.current
                .setLngLat({
                  lng: placesToRender[0].longitude,
                  lat: placesToRender[0].latitude
                })
                .setHTML(renderInfoPopup({ places: placesToRender }))
                .addTo(mapbox.current);
            }}
            onMouseEnter={() =>
              (mapbox.current.getCanvas().style.cursor = "pointer")
            }
            onMouseLeave={e => {
              mapbox.current.getCanvas().style.cursor = "";
            }}
            paint={{
              "circle-color": "rgb(66, 100, 251)",
              "circle-radius": 13
            }}
          />
          <Layer
            type="symbol"
            id="staging_places_multiple_count_layer"
            sourceId="mn_staging_places"
            filter={[">", ["to-number", ["get", "total"]], 1]}
            sourceLayer={"polygons"}
            layout={{
              "text-allow-overlap": true,
              "icon-allow-overlap": true,
              "text-ignore-placement": true,
              "text-field": "{total}",
              "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
              "text-size": 15
            }}
          />
          <Layer
            type="symbol"
            id="staging_places_layer"
            sourceId="mn_staging_places"
            sourceLayer={"polygons"}
            filter={["==", ["to-number", ["get", "total"]], 1]}
            images={["marker", greenMarkerImg]}
            onMouseEnter={() =>
              (mapbox.current.getCanvas().style.cursor = "pointer")
            }
            onClick={e => {
              const info = e.features[0].properties;
              const placeId = parseInt(info.place_ids);
              const place = places[placeId];

              mapbox.current.flyTo({
                center: { lng: place.longitude, lat: place.latitude },
                zoom: 18,
                speed: 0.8
              });

              popup.current
                .setLngLat({ lng: place.longitude, lat: place.latitude })
                .setHTML(renderInfoPopup({ places: [place] }))
                .addTo(mapbox.current);
            }}
            onMouseLeave={e => {
              mapbox.current.getCanvas().style.cursor = "";
            }}
            layout={{
              "icon-image": "marker",
              "icon-anchor": "bottom",
              "icon-allow-overlap": true,
              "icon-ignore-placement": true
            }}
          />
        </Map>
      </Container>
    );
  },
  (oldProps, newProps) => {
    return (
      oldProps.loading === false &&
      newProps.loading === true &&
      oldProps.height === newProps.height &&
      oldProps.place.latitude === newProps.place.latitude &&
      oldProps.place.longitude === newProps.place.longitude
    );
  }
);
