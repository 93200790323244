import styled from "styled-components";
import { Button, DropdownItem } from "styled-bootstrap-components";

export const DropdownToggle = styled(Button)`
  border: 0;
  background-color: ${props =>
    props.disabled ? props.theme.grey : props.theme.blue};
  :hover {
    border: 0 !important;
    background-color: ${props =>
      props.disabled ? props.theme.grey : props.theme.blue}!important;
    box-shadow: none;
  }
  :focus {
    border: 0 !important;
    background-color: ${props =>
      props.disabled ? props.theme.grey : props.theme.blue}!important;
    box-shadow: none;
  }
`;

export const Action = styled(DropdownItem)`
  :hover {
    cursor: pointer;
  }
`;
