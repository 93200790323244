import React, { useState } from "react";
import brandsStore from "../../store/brands";
import naicsStore from "../../store/naics";
import { renderDef } from "../../utils/functions";

import {
  Container,
  ErrorContainer,
  ButtonContainer,
  ConfirmButton,
  CancelButton,
  WarningIcon,
  SelectorContainer,
  StyledSelect,
  StyledSpinner
} from "./styles";

export default props => {
  const { brand, hideModal } = props;
  const {
    data: naics,
    loading: loadingNaics,
    error: errorNaics
  } = naicsStore.useData();
  const selectedBrands = brandsStore.useSelected();
  const {
    loading: editingBrand,
    status: editingStatus,
    error: editingError,
    makeRequest: editBrand
  } = brandsStore.usePutNewData();
  const [value, setValue] = useState();
  const [askForConfirmation, setAskForConfirmation] = useState(false);

  const brandsToUpdate = [...selectedBrands];
  if (!brandsToUpdate.find(sb => sb.mn_brand_id === brand.mn_brand_id))
    brandsToUpdate.push(brand);

  if (editingStatus === 200) hideModal();

  const onConfirm = () => {
    if (!askForConfirmation && brandsToUpdate.length > 1)
      return setAskForConfirmation(true);
    setAskForConfirmation(false);

    editBrand({
      data: brandsToUpdate.map(brand => ({
        ...brand,
        naics_code: value.value
      }))
    });
  };

  const renderError = ({ message, error }) => (
    <ErrorContainer>
      <WarningIcon />
      {message} <p>{error.message}</p>
    </ErrorContainer>
  );

  const renderLoading = ({ message }) => (
    <>
      <StyledSpinner /> {message}
    </>
  );

  const renderSelect = () => {
    const selectNaics = naics.map(naics => {
      const {
        naics_us_title,
        naics_code,
        tier_1,
        tier_2,
        tier_3,
        tier_4
      } = naics;
      return {
        label: renderDef([
          naics_us_title,
          naics_code,
          tier_1,
          tier_2,
          tier_3,
          tier_4
        ]),
        value: naics_code
      };
    });

    return (
      <>
        <SelectorContainer>
          Select Naics Code
          <StyledSelect
            options={selectNaics}
            value={value}
            onChange={setValue}
          />
        </SelectorContainer>
        <ButtonContainer>
          <ConfirmButton onClick={onConfirm}>Confirm</ConfirmButton>
          <CancelButton onClick={hideModal}>Cancel</CancelButton>
        </ButtonContainer>
      </>
    );
  };

  const renderConfirmation = () => {
    return (
      <>
        <p>
          The selected naics code will be applied to {brandsToUpdate.length}
          brands. Do you want to continue?
        </p>
        <ButtonContainer>
          <ConfirmButton onClick={onConfirm}>Confirm</ConfirmButton>
          <CancelButton onClick={hideModal}>Cancel</CancelButton>
        </ButtonContainer>
      </>
    );
  };

  const renderContent = () => {
    if (errorNaics)
      return renderError({
        message: "There was an error loading naics",
        error: renderError
      });
    if (editingError)
      return renderError({
        message: "There was an error setting the new naics code",
        error: editingError
      });
    if (loadingNaics) return renderLoading({ message: "Loading Naics..." });
    if (askForConfirmation) return renderConfirmation();
    if (editingBrand)
      return renderLoading({ message: "Setting the new naics code..." });
    return renderSelect();
  };

  return <Container>{renderContent()}</Container>;
};
