import React, { useState } from "react";
import sourcesStore from "../../store/sources";
import stagingPlacesStore from "../../store/stagingPlaces";
import {
  Container,
  ErrorContainer,
  ButtonContainer,
  ConfirmButton,
  CancelButton,
  WarningIcon,
  SelectorContainer,
  StyledSelect,
  StyledSpinner
} from "./styles";

export default props => {
  const { place, hideModal } = props;
  const {
    data: sources,
    loading: loadingSources,
    error: errorSources
  } = sourcesStore.useData();
  const selectedPlaces = stagingPlacesStore.useSelected();
  const {
    loading: editingPlace,
    status: editingStatus,
    error: editingError,
    makeRequest: editPlace
  } = stagingPlacesStore.usePutNewData();
  const [value, setValue] = useState();
  const [askForConfirmation, setAskForConfirmation] = useState(false);

  const placesToUpdate = [...selectedPlaces];
  if (place && !placesToUpdate.find(sp => sp.id === place.id))
    placesToUpdate.push(place);

  if (editingStatus === 200) hideModal();

  const onConfirm = () => {
    if (!askForConfirmation && placesToUpdate.length > 1)
      return setAskForConfirmation(true);
    setAskForConfirmation(false);

    editPlace({
      data: placesToUpdate.map(place => ({
        ...place,
        mn_source_id: value.value,
        mn_source_name: value.label
      })),
      query: { noGeocode: true }
    });
  };

  const renderError = ({ message, error }) => (
    <ErrorContainer>
      <WarningIcon />
      {message} <p>{error.message}</p>
    </ErrorContainer>
  );

  const renderLoading = ({ message }) => (
    <>
      <StyledSpinner /> {message}
    </>
  );

  const renderSelect = () => {
    const selectOptions = sources.map(source => {
      return {
        label: source.mn_source_name,
        value: source.mn_source_id
      };
    });

    return (
      <>
        <SelectorContainer>
          Select a Source
          <StyledSelect
            options={selectOptions}
            value={value}
            onChange={setValue}
          />
        </SelectorContainer>
        <ButtonContainer>
          <ConfirmButton onClick={onConfirm}>Confirm</ConfirmButton>
          <CancelButton onClick={hideModal}>Cancel</CancelButton>
        </ButtonContainer>
      </>
    );
  };

  const renderConfirmation = () => {
    return (
      <>
        <p>
          The selected source will be applied to {placesToUpdate.length} places.
          Do you want to continue?
        </p>
        <ButtonContainer>
          <ConfirmButton onClick={onConfirm}>Confirm</ConfirmButton>
          <CancelButton onClick={hideModal}>Cancel</CancelButton>
        </ButtonContainer>
      </>
    );
  };

  const renderContent = () => {
    if (errorSources)
      return renderError({
        message: "There was an error loading sources",
        error: errorSources
      });
    if (editingError)
      return renderError({
        message: "There was an error setting the new source",
        error: editingError
      });
    if (loadingSources) return renderLoading({ message: "Loading Sources..." });
    if (askForConfirmation) return renderConfirmation();
    if (editingPlace)
      return renderLoading({ message: "Setting the new source..." });
    return renderSelect();
  };

  return <Container>{renderContent()}</Container>;
};
