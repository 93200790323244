import styled from "styled-components";
import { Select } from "@smooth-ui/core-sc";

export const SearchContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 2px;
`;

export const SearchFieldSelect = styled(Select)`
  // margin-bottom: 8px;
`;

export const SearchInput = styled.input`
  width: 40%;
  margin-right: 5px;
  background-color: ${props => props.theme.lightGrey};
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1px solid ${props => props.theme.grey};
  outline: none;

  :focus {
    border-bottom-color: ${props => props.theme.darkGrey};
  }
`;
