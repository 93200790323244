import styled from "styled-components";
import { MultiGrid } from "react-virtualized";

export const Table = styled(MultiGrid)`
  border: 1px solid #dee2e6;
  font-size: 13px;
  font-weight: 300;
  outline: none;

  ::-webkit-scrollbar {
    width: 0px; /* remove scrollbar space */
    background: transparent; /* optional: just make scrollbar invisible */
  }
`;

export const Container = styled.div`
  border-bottom: 1px solid #dee2e6;
  width: 100%;
`;

export const CheckboxContainer = styled.div.attrs(props => ({
  style: props.vStyle
}))`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  background-color: ${props => (props.header ? props.theme.blue : "white")};
  border: 1px solid #dee2e6;
  border-top: 0;
  border-left: 0;
  border-right: 0;
`;
