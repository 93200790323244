import React from "react";
import { useInputValue } from "../../utils/hooks";
import tagStore from "../../store/tags";

import {
  Container,
  ErrorContainer,
  ButtonContainer,
  ConfirmButton,
  CancelButton,
  WarningIcon,
  TagNameInput,
  StyledSpinner
} from "./styles";

export default props => {
  const { hideModal } = props;
  const { loading, status, error, makeRequest } = tagStore.usePostNewData();
  const [value, setValue] = useInputValue();

  if (status === 200) hideModal();

  const onConfirm = () => {
    makeRequest({
      data: [{ mn_tag_name: value }]
    });
  };

  const renderError = ({ message, error }) => (
    <ErrorContainer>
      <WarningIcon />
      {message} <p>{error.message}</p>
    </ErrorContainer>
  );

  const renderLoading = ({ message }) => (
    <>
      <StyledSpinner /> {message}
    </>
  );

  const renderSelect = () => {
    return (
      <>
        Tag Name
        <TagNameInput onChange={setValue} value={value} />
        <ButtonContainer>
          <ConfirmButton onClick={onConfirm}>Confirm</ConfirmButton>
          <CancelButton onClick={hideModal}>Cancel</CancelButton>
        </ButtonContainer>
      </>
    );
  };

  const renderContent = () => {
    if (error)
      return renderError({
        message: "There was an error creating the new tag",
        error
      });
    if (loading) return renderLoading({ message: "Creating tag..." });
    return renderSelect();
  };

  return <Container>{renderContent()}</Container>;
};
