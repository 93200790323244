import styled from "styled-components";

import { Spinner } from "../utils/styles";

export const StyledSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
  margin-right: 2px;
`;

export const MessageContainer = styled.div`
  font-size: 15px;
  margin-bottom: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
