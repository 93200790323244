import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import Menu from "../Menu/Menu";
import Header from "../Header/Header";
import PlacesImporter from "../PlacesImporter/PlacesImporter";
import BrandsTable from "../tables/BrandsTable/BrandsTable";
import TagsTable from "../tables/TagsTable/TagsTable";
import SourcesTable from "../tables/SourcesTable/SourcesTable";
import Map from "../Map/Map";
import { StyledRouter, Container, Main, MainContainer } from "./styles";
import { getAuthenticatedUser } from "../utils/auth";

async function redirectIfNotLoggedIn() {
  try {
    const user = await getAuthenticatedUser();
    if (!user) navigate("/login");
  } catch (e) {
    navigate("/login");
  }
}

export default () => {
  const [expandedMenu, setExpandedMenu] = useState(false);
  const toggleMenu = () => {
    console.log(expandedMenu);
    setExpandedMenu(!expandedMenu);
  };

  useEffect(() => {
    redirectIfNotLoggedIn();
  }, []);

  return (
    <Container expandedMenu={expandedMenu}>
      <Header toggleMenu={toggleMenu} />
      {expandedMenu && <Menu />}
      <Main>
        <MainContainer>
          <StyledRouter>
            <PlacesImporter path="/" />
            <BrandsTable path="/brands" />
            <TagsTable path="/tags" />
            <SourcesTable path="/sources" />
            <Map path="/map" />
          </StyledRouter>
        </MainContainer>
      </Main>
    </Container>
  );
};
