import React, { useState, useEffect, useMemo } from "react";
import { useModal as useReactModal } from "react-modal-hook";
import ReactModal from "react-modal";
import { getAuthenticatedUser, getCurrentSession } from "../utils/auth";

export const useInputValue = (defaultValue = "") => {
  const [value, setValue] = useState(defaultValue);
  const onChange = event => {
    setValue(event && event.target && event.target.value);
  };

  return [value, onChange, setValue];
};

export const useTimeout = ms => {
  const [finished, setFinished] = useState(false);
  setTimeout(() => setFinished(true), ms);
  return [finished, setFinished];
};

export const useModal = (content, { top, left, right, bottom, variables }) => {
  const ModalContent = content();
  const styles = {
    content: {
      top: top || "40%",
      left: left || "50%",
      right: right || "auto",
      bottom: bottom || "auto",
      transform: "translate(-50%, -50%)",
      overflow: "visible",
      zIndex: 1001
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgb(103,117,124, 0.7)"
    }
  };

  let showModal, hideModal;

  [showModal, hideModal] = useReactModal(() => {
    const ModalContentWithProps = ({ hideModal }) =>
      React.cloneElement(ModalContent, { hideModal });

    const Modal = useMemo(
      () => (
        <ReactModal
          isOpen
          ariaHideApp={false}
          style={styles}
          onRequestClose={hideModal}
        >
          {React.cloneElement(<ModalContentWithProps />, { hideModal })}
        </ReactModal>
      ),
      []
    );

    return Modal;
  }, variables);

  return [showModal, hideModal];
};

export const useAuthenticatedUser = () => {
  const [user, setUser] = useState();

  const capitalize = s => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  async function getUser() {
    const authenticatedUser = await getAuthenticatedUser();
    const session = await getCurrentSession();
    if (authenticatedUser) {
      const email = authenticatedUser.attributes.email;
      const username = capitalize(email.split("@")[0]);
      const token = session.accessToken && session.accessToken.jwtToken;

      setUser({
        email,
        username,
        token
      });
    }
  }

  useEffect(() => {
    getUser();
  }, []);
  return user;
};
