import React, { useState } from "react";
import { format } from "date-fns";
import brandsStore from "../../store/brands";
import { useModal } from "../../utils/hooks";
import { Spinner } from "../../utils/styles";
import {
  Container,
  StyledSpinner,
  TableActions,
  DeleteButton,
  MessageContainer,
  ActionButtonsContainer
} from "../styles";
import { InsertButton } from "./styles";

import DeleteConfirmation from "../../modals/DeleteConfirmation/DeleteConfirmation";
import CreateBrand from "../../modals/CreateBrand/CreateBrand";
import IabCategorySelector from "../../modals/IabCategorySelector/IabCategorySelector";
import NaicsSelector from "../../modals/NaicsSelector/NaicsSelector";
import SegmentSelector from "../../modals/SegmentSelector/SegmentSelector";
import ParentSelector from "../../modals/ParentBrandSelector/ParentBrandSelector";
import TagsSelector from "../../modals/TagsSelector/TagsSelector";

import Table from "../Table/Table";
import Search from "../Search/Search";

export default () => {
  const {
    data,
    error,
    loading,
    sortField,
    sortDirection,
    numElemsSelected,
    firstLoad
  } = brandsStore.useData();
  const sort = brandsStore.useSort();
  const {
    currentFilter,
    filterField,
    setCurrentFilter,
    setFilterField
  } = brandsStore.useFilter();
  const togglePlace = brandsStore.useToggleElement();
  const toggleAllPlaces = brandsStore.useToggleAllElements();
  const [currentBrand, setCurrentBrand] = useState();
  const {
    makeRequest: updateBrands,
    loading: updateLoading
  } = brandsStore.usePutNewData();

  const [ShowDeleteConfirmationModal] = useModal(
    () => (
      <DeleteConfirmation
        useRequest={brandsStore.useDeleteSelected}
        confirmationMessage={`${numElemsSelected} brands will be deleted.
        Do you want to continue?`}
        loadingMessage={"Deleting brands..."}
      />
    ),
    {
      variables: [numElemsSelected]
    }
  );

  const [showCreateBrandModal] = useModal(() => <CreateBrand />, {
    variables: []
  });

  const [showIabCategorySelectorModal] = useModal(
    () => <IabCategorySelector brand={currentBrand} />,
    {
      variables: [currentBrand]
    }
  );
  const [showSegmentSelectorModal] = useModal(
    () => <SegmentSelector brand={currentBrand} />,
    {
      variables: [currentBrand]
    }
  );
  const [showParentSelectorModal] = useModal(
    () => <ParentSelector brand={currentBrand} />,
    {
      variables: [currentBrand]
    }
  );
  const [showNaicsSelectorModal] = useModal(
    () => <NaicsSelector brand={currentBrand} />,
    {
      variables: [currentBrand]
    }
  );
  const [showTagsSelectorModal] = useModal(
    () => <TagsSelector brand={currentBrand} />,
    {
      variables: [currentBrand]
    }
  );

  const onEditField = row => {
    updateBrands({ data: [row] });
  };

  const fields = [
    { name: "brand_name", width: 150, label: "Brand", onEdit: onEditField },
    {
      name: "parent_brand_name",
      width: 150,
      label: "Parent",
      onClick: row => {
        setCurrentBrand(row);
        showParentSelectorModal();
      }
    },
    {
      name: "segment_name",
      width: 150,
      label: "Segment",
      onClick: row => {
        setCurrentBrand(row);
        showSegmentSelectorModal();
      }
    },
    {
      name: "naics_name",
      width: 150,
      label: "Naics",
      onClick: row => {
        setCurrentBrand(row);
        showNaicsSelectorModal();
      }
    },
    {
      name: "iab_category_name",
      width: 150,
      label: "Iab Category",
      onClick: row => {
        setCurrentBrand(row);
        showIabCategorySelectorModal();
      }
    },
    {
      name: "tags",
      width: 150,
      label: "Tags",
      format: value => value && value.map(tag => tag.mn_tag_name).join(", "),
      onClick: row => {
        setCurrentBrand(row);
        showTagsSelectorModal();
      }
    },
    {
      name: "created_at",
      width: 80,
      label: "Created At",
      format: value => value && format(new Date(value), "YYYY-MM-DD")
    },
    {
      name: "updated_at",
      width: 80,
      label: "Updated At",
      format: value => value && format(new Date(value), "YYYY-MM-DD")
    },
    {
      name: "deleted_at",
      width: 80,
      label: "Deleted At",
      format: value => value && format(new Date(value), "YYYY-MM-DD")
    }
  ];

  const onFilterChange = e => {
    const value = e.target.value;
    setCurrentFilter({ value });
  };

  const onFilterFieldChange = e => {
    const value = e.target.value;
    setFilterField({ field: value });
  };

  const onSelectAll = allRowsSelected => {
    toggleAllPlaces({ selected: allRowsSelected });
  };

  const onSelectBrand = brand => {
    togglePlace({ id: brand.mn_brand_id });
  };

  const renderMessage = () => {
    if (error)
      return <span>There was an error loading brands. {error.message}</span>;
    if (updateLoading) {
      return (
        <>
          <StyledSpinner />
          Updating Brand...
        </>
      );
    }
    if (loading)
      return (
        <span>
          <Spinner /> Loading...
        </span>
      );
    if (numElemsSelected)
      return (
        <span>
          {numElemsSelected} {numElemsSelected === 1 ? "brand" : "brands"}{" "}
          selected
        </span>
      );
  };

  return (
    <Container>
      <TableActions>
        {firstLoad && (
          <ActionButtonsContainer>
            <InsertButton onClick={showCreateBrandModal}>
              New Brand
            </InsertButton>
            <DeleteButton
              onClick={ShowDeleteConfirmationModal}
              disabled={numElemsSelected === 0}
            >
              Delete
            </DeleteButton>
          </ActionButtonsContainer>
        )}
        <MessageContainer>{renderMessage()}</MessageContainer>
        {firstLoad && (
          <Search
            currentFilter={currentFilter}
            filterField={filterField}
            onFilterChange={onFilterChange}
            onFilterFieldChange={onFilterFieldChange}
            fields={fields}
          />
        )}
      </TableActions>
      <Table
        selectable
        height={800}
        emptyRows={25}
        fixCheckboxes={true}
        fields={fields}
        sortField={sortField}
        sortDirection={sortDirection}
        data={data}
        onSort={sort}
        onSelectAll={onSelectAll}
        onSelectRow={onSelectBrand}
      />
    </Container>
  );
};
