import Amplify, { Auth } from "aws-amplify";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID
  }
});

export let user;
export let token;

export const singIn = async ({ username, password }) => {
  try {
    user = await Auth.signIn(username, password);

    console.log(user.challengeName);

    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      const error = new Error();
      error.code = "NEW_PASSWORD_REQUIRED";
      throw error;
    }
  } catch (error) {
    throw error;
  }
};

export const completeNewPassword = async ({ password }) => {
  user = await Auth.completeNewPassword(user, password);
};

export const logOut = () => {
  return Auth.signOut({ global: true });
};

export const getAuthenticatedUser = () => {
  return Auth.currentAuthenticatedUser();
};

export const getCurrentSession = async () => {
  const session = await Auth.currentSession();
  token = session.idToken.jwtToken;
  return session;
};
