import React from "react";

import { SearchContainer, SearchInput, SearchFieldSelect } from "./styles";

export default ({
  currentFilter,
  filterField,
  onFilterChange,
  onFilterFieldChange,
  fields
}) => {
  return (
    <SearchContainer>
      Search:
      <SearchInput value={currentFilter} onChange={onFilterChange} />
      <SearchFieldSelect
        size="sm"
        onChange={onFilterFieldChange}
        value={filterField}
      >
        {fields
          .filter(field => field.name)
          .map(field => (
            <option key={`op-${field.name}`} value={field.name}>
              {field.label}
            </option>
          ))}
      </SearchFieldSelect>
    </SearchContainer>
  );
};
