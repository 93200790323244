import React, { useState } from "react";
import stagingPlacesStore from "../../store/stagingPlaces";
import { useModal } from "../../utils/hooks";
import {
  Container,
  TableActions,
  ActionButtonsContainer,
  MessageContainer,
  StyledSpinner
} from "../styles";
import { MapIcon, GeocodeButton, TickIcon, WarningIcon } from "./styles";
import Table from "../Table/Table";
import Search from "../Search/Search";
import BrandSelector from "../../modals/BrandSelector/BrandSelector";
import DistributedSelector from "../../modals/DistributedSelector/DistributedSelector";
import SourceSelector from "../../modals/SourceSelector/SourceSelector";
import CountrySelector from "../../modals/CountrySelector/CountrySelector";
import GeocodeConfirmation from "../../modals/GeocodeConfirmation/GeocodeConfirmation";
import DeployConfirmation from "../../modals/DeployConfirmation/DeployConfirmation";
import DeleteConfirmation from "../../modals/DeleteConfirmation/DeleteConfirmation";
import Dropdown from "../../Dropdown/Dropdown";

export default ({ onMapClick, currentMapPlace, height }) => {
  const {
    data,
    sortField,
    sortDirection,
    firstLoad
  } = stagingPlacesStore.useData();
  const sort = stagingPlacesStore.useSort();
  const [currentPlace, setCurrentPlace] = useState();
  const {
    currentFilter,
    filterField,
    setCurrentFilter,
    setFilterField
  } = stagingPlacesStore.useFilter();
  const {
    makeRequest: updatePlaces,
    loading: updateLoading
  } = stagingPlacesStore.usePutNewData();
  const { geocode, loading: geocodeLoading } = stagingPlacesStore.useGeocoder();
  const { deploy, loading: deployLoading } = stagingPlacesStore.useDeploy();
  const {
    resetSelected,
    loading: resetLoading
  } = stagingPlacesStore.useResetSelected();
  const togglePlace = stagingPlacesStore.useToggleElement();
  const toggleAllPlaces = stagingPlacesStore.useToggleAllElements();
  const { numElemsSelected } = stagingPlacesStore.useData();
  const selectedPlaces = stagingPlacesStore.useSelected();

  const [showBrandSelectorModal] = useModal(
    () => <BrandSelector place={currentPlace} />,
    {
      variables: [currentPlace]
    }
  );

  const [showSourceSelectorModal] = useModal(
    () => <SourceSelector place={currentPlace} />,
    {
      variables: [currentPlace]
    }
  );

  const [showDistributedSelectorModal] = useModal(
    () => <DistributedSelector place={currentPlace} />,
    {
      variables: [currentPlace]
    }
  );

  const [showCountrySelectorModal] = useModal(
    () => <CountrySelector place={currentPlace} />,
    {
      variables: [currentPlace]
    }
  );

  const [showGeocodeConfirmationModal] = useModal(
    () => <GeocodeConfirmation places={selectedPlaces} geocode={geocode} />,
    {
      variables: [selectedPlaces]
    }
  );

  const [showDeployConfirmationModal] = useModal(
    () => <DeployConfirmation places={selectedPlaces} deploy={deploy} />,
    {
      variables: [selectedPlaces]
    }
  );

  const [ShowDeleteConfirmationModal] = useModal(
    () => (
      <DeleteConfirmation
        useRequest={stagingPlacesStore.useDeleteSelected}
        confirmationMessage={`${numElemsSelected} places will be deleted from both tables.
        Do you want to continue?`}
        loadingMessage={"Deleting places..."}
      />
    ),
    {
      variables: [numElemsSelected]
    }
  );

  /* useEffect(() => {
    if (currentPlace !== null) showBrandSelectorModal();
  }, [currentPlace]); */

  const onEditField = row => {
    updatePlaces({ data: [row], query: { noGeocode: true } });
  };

  const fields = [
    {
      render: row => {
        if (row) {
          if (row.duplicated === true) return <WarningIcon />;
          if (row.geocoded === true) return <TickIcon />;
          if (row.geocoded === false) return <WarningIcon />;
        }

        return null;
      },
      width: 30,
      sortable: false,
      tooltipType: row => {
        if (row) {
          if (row.duplicated === true) return "error";
          if (row.geocoded === true) return "info";
          if (row.geocoded === false) return "error";
        }
      },
      showTooltip: row => {
        if (row) {
          if (row.duplicated === true)
            return "Duplicated place. A place with the same brand and address already exists in production";
          if (row.geocoded === true)
            return "The place has already been geocoded";
          if (row.geocoded === false)
            return "The place has not been geocoded yet";
        }
      }
    },
    {
      render: row => {
        return (
          row &&
          row.latitude &&
          !row.latitude_error && (
            <MapIcon
              selected={currentMapPlace && row.id === currentMapPlace.id}
            />
          )
        );
      },
      width: 30,
      sortable: false,
      onClick: onMapClick
    },
    {
      name: "mn_chain_brand_name",
      width: 150,
      label: "Brand",
      onClick: row => {
        setCurrentPlace(row);
        showBrandSelectorModal();
      },
      // onEdit: onEditField,
      showError: row => row.mn_chain_brand_name_error,
      showWarning: row => row.mn_chain_brand_name_warning
    },
    {
      name: "distributed",
      width: 150,
      label: "Distributed",
      format: value =>
        value && value.map(brand => brand.mn_chain_brand_name).join(", "),
      onClick: row => {
        setCurrentPlace(row);
        showDistributedSelectorModal();
      }
    },
    {
      name: "place_name",
      width: 150,
      label: "Name",
      onEdit: onEditField
    },
    // { name: "parent_place", width: 200, label: "Parent" },
    {
      name: "street_name",
      width: 170,
      label: "Street",
      onEdit: onEditField,
      showError: row => row.street_name_error,
      showSuccess: row =>
        row.street_name_edited && "The street name has been manually edited",
      showWarning: row => row.street_name_warning
    },
    {
      name: "street_number",
      width: 80,
      label: "St Num",
      onEdit: onEditField,
      showError: row => row.street_number_error,
      showSuccess: row =>
        row.street_number_edited &&
        "The street number has been manually edited",
      showWarning: row => row.street_number_warning
    },
    {
      name: "full_address",
      width: 220,
      label: "Full Address",
      onEdit: onEditField,
      showSuccess: row =>
        row.full_address_edited && "The full address has been manually edited",
      showWarning: row => row.full_address_warning
    },
    {
      name: "postal_code",
      width: 100,
      label: "Zipcode",
      onEdit: onEditField,
      showSuccess: row =>
        row.postal_code_edited && "The zipcode has been manually edited"
    },
    {
      name: "city_name",
      width: 150,
      label: "City",
      onEdit: onEditField,
      showSuccess: row =>
        row.city_name_edited && "The city name has been manually edited",
      showWarning: row => row.city_name_warning
    },
    {
      name: "county_name",
      width: 100,
      label: "County",
      showSuccess: row =>
        row.county_name_edited && "The county name has been manually edited",
      onEdit: onEditField
    },
    {
      name: "region_code",
      width: 100,
      label: "Region",
      onEdit: onEditField,
      showSuccess: row =>
        row.region_code_edited && "The region code has been manually edited",
      showWarning: row => row.region_code_warning
    },
    {
      name: "country_code",
      width: 80,
      label: "Country",
      onClick: row => {
        setCurrentPlace(row);
        showCountrySelectorModal();
      },
      showError: row => row.country_code_error,
      showSuccess: row =>
        row.country_code_edited && "The country code has been manually edited",
      showWarning: row => row.country_code_warning
    },
    {
      name: "latitude",
      width: 100,
      label: "Lat",
      onEdit: onEditField,
      format: val => val && val.toString().substring(0, 10),
      showError: row => row.latitude_error,
      showWarning: row => row.latitude_warning
    },
    {
      name: "longitude",
      width: 100,
      label: "Lng",
      onEdit: onEditField,
      format: val => val && val.toString().substring(0, 10),
      showError: row => row.longitude_error,
      showWarning: row => row.longitude_warning
    },
    { name: "dma_code", width: 100, label: "DMA" },
    { name: "geo_radius", width: 80, label: "Radius", onEdit: onEditField },
    /* {
      name: "geo_hash",
      width: 120,
      label: "Geohash",
      showError: row => {
        return row.geo_hash_error;
      }
    }, */
    {
      name: "mn_footprint_id",
      width: 100,
      label: "Footprint",
      render: row => {
        if (row.mn_footprint_id) return "YES";
        return "NO";
      }
    },
    {
      name: "mn_source_name",
      width: 150,
      label: "Source",
      onClick: row => {
        setCurrentPlace(row);
        showSourceSelectorModal();
      }
    },
    { name: "phone_number", width: 200, label: "Phone", onEdit: onEditField },
    { name: "open_hours", width: 200, label: "Open Hours", onEdit: onEditField }
  ];

  const onFilterChange = e => {
    const value = e.target.value;
    setCurrentFilter({ value });
  };

  const onFilterFieldChange = e => {
    const value = e.target.value;
    setFilterField({ field: value });
  };

  const onSelectAll = allRowsSelected => {
    toggleAllPlaces({ selected: allRowsSelected });
  };

  const onSelectPlace = place => {
    togglePlace({ id: place.id });
  };

  const onResetToOriginal = () => {
    resetSelected();
  };

  const onGeocode = () => {
    const manuallyEditedFields = selectedPlaces.some(place => {
      if (place.street_name_edited) return true;
      if (place.street_number_edited) return true;
      if (place.full_address_edited) return true;
      if (place.postal_code_edited) return true;
      if (place.country_code_edited) return true;
      if (place.county_name_edited) return true;
      if (place.city_name_edited) return true;
      if (place.region_code_edited) return true;
      if (!place.mn_chain_brand_id) return true;
      return false;
    });
    const noBrand = selectedPlaces.some(place => !place.mn_chain_brand_id);
    if (manuallyEditedFields || noBrand) showGeocodeConfirmationModal();
    else geocode({ places: selectedPlaces });
  };

  const renderTopMessage = () => {
    if (updateLoading) {
      return (
        <>
          <StyledSpinner />
          Validating place...
        </>
      );
    }

    if (deployLoading) {
      return (
        <>
          <StyledSpinner />
          Deploying places...
        </>
      );
    }

    if (geocodeLoading) {
      return (
        <>
          <StyledSpinner />
          Geocoding places...
        </>
      );
    }

    if (resetLoading) {
      return (
        <>
          <StyledSpinner />
          Reseting places...
        </>
      );
    }

    return "Validated Places";
  };

  return (
    <Container>
      <TableActions>
        {firstLoad && (
          <ActionButtonsContainer>
            <Dropdown
              disabled={numElemsSelected === 0}
              actions={[
                { name: "Geocode", onClick: onGeocode },
                { name: "Deploy", onClick: showDeployConfirmationModal },
                { name: "Reset to Original", onClick: onResetToOriginal },
                { name: "Delete Places", onClick: ShowDeleteConfirmationModal },
                { name: "Edit Brand", onClick: showBrandSelectorModal },
                { name: "Edit Country", onClick: showCountrySelectorModal },
                {
                  name: "Edit Distributed Brands",
                  onClick: showDistributedSelectorModal
                }
              ]}
            />
          </ActionButtonsContainer>
        )}
        <MessageContainer> {renderTopMessage()} </MessageContainer>
        {firstLoad && (
          <Search
            currentFilter={currentFilter}
            filterField={filterField}
            onFilterChange={onFilterChange}
            onFilterFieldChange={onFilterFieldChange}
            fields={fields}
          />
        )}
      </TableActions>
      <Table
        height={height}
        fields={fields}
        sortField={sortField}
        sortDirection={sortDirection}
        data={data}
        selectable
        fixedColumnCount={3}
        onSort={sort}
        onSelectAll={onSelectAll}
        onSelectRow={onSelectPlace}
      />
    </Container>
  );
};
