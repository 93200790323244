import React, { useState } from "react";
import {
  Container,
  ButtonContainer,
  ConfirmButton,
  CancelButton,
  ConfirmationContainer
} from "./styles";

export default ({ deploy, places, hideModal }) => {
  const placesWithoutErrors = places.filter(place => {
    if (place.street_name_error) return false;
    if (place.street_number_error) return false;
    if (place.full_address_error) return false;
    if (place.postal_code_error) return false;
    if (place.country_code_error) return false;
    if (place.county_name_error) return false;
    if (place.city_name_error) return false;
    if (place.region_code_error) return false;
    if (place.geo_radius_error) return false;
    if (!place.geocoded) return false;
    if (place.duplicated) return false;
    if (!place.mn_chain_brand_id) return false;
    return true;
  }, 0);

  const placesWithErrorsCount = places.length - placesWithoutErrors.length;

  const getInitalWarning = () => {
    if (placesWithErrorsCount > 0) {
      if (places.length > placesWithErrorsCount) return "invalidPlacesWarning";
      return "invalidPlacesError";
    }
    return "deployConfirmation";
  };

  const [warning] = useState(getInitalWarning());

  const onYes = () => {
    if (
      warning === "invalidPlacesWarning" ||
      warning === "deployConfirmation"
    ) {
      deploy({
        places: placesWithoutErrors
      });
      hideModal();
    }
  };
  const onNo = () => {
    hideModal();
  };

  const getMessage = () => {
    if (warning === "invalidPlacesError")
      return `Places with errors cannot be deployed`;
    if (warning === "invalidPlacesWarning")
      return `There are ${placesWithErrorsCount} places with errors. Only ${
        placesWithoutErrors.length
      } will be deployed. Do you want to continue?`;
    if (warning === "deployConfirmation")
      return `${
        placesWithoutErrors.length
      } places will be deployed to production.  Do you want to continue?`;
  };

  return (
    <Container>
      <ConfirmationContainer>
        <p>{getMessage()}</p>
        <ButtonContainer>
          {warning === "invalidPlacesError" ? (
            <CancelButton onClick={hideModal}>Close</CancelButton>
          ) : (
            <>
              <ConfirmButton onClick={onYes}>Yes</ConfirmButton>
              <ConfirmButton onClick={onNo}>No</ConfirmButton>
              <CancelButton onClick={hideModal}>Cancel</CancelButton>
            </>
          )}
        </ButtonContainer>
      </ConfirmationContainer>
    </Container>
  );
};
