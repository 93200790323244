import React, { useState } from "react";
import brandsStore from "../../store/brands";
import stagingPlacesStore from "../../store/stagingPlaces";
import {
  Container,
  ErrorContainer,
  ButtonContainer,
  ConfirmButton,
  CancelButton,
  WarningIcon,
  SelectorContainer,
  StyledSelect,
  StyledSpinner
} from "./styles";

export default props => {
  const { place, hideModal } = props;
  const {
    data: brands,
    loading: loadingBrands,
    error: errorBrands
  } = brandsStore.useData();
  const selectedPlaces = stagingPlacesStore.useSelected();
  const {
    loading: editingPlace,
    status: editingStatus,
    error: editingError,
    makeRequest: editPlace
  } = stagingPlacesStore.usePutNewData();
  const [value, setValue] = useState();
  const [askForConfirmation, setAskForConfirmation] = useState(false);

  const placesToUpdate = [...selectedPlaces];
  if (place && !placesToUpdate.find(sp => sp.id === place.id))
    placesToUpdate.push(place);

  if (editingStatus === 200) hideModal();

  const onConfirm = () => {
    if (!askForConfirmation && placesToUpdate.length > 1)
      return setAskForConfirmation(true);
    setAskForConfirmation(false);

    editPlace({
      data: placesToUpdate.map(place => ({
        ...place,
        mn_chain_brand_id: value.value,
        mn_chain_brand_name: value.label
      }))
    });
  };

  const renderError = ({ message, error }) => (
    <ErrorContainer>
      <WarningIcon />
      {message} <p>{error.message}</p>
    </ErrorContainer>
  );

  const renderLoading = ({ message }) => (
    <>
      <StyledSpinner /> {message}
    </>
  );

  const renderSelect = () => {
    const selectOptions = brands.map(brand => {
      return {
        label: brand.brand_name,
        value: brand.mn_brand_id
      };
    });

    return (
      <>
        <SelectorContainer>
          Select a Brand
          <StyledSelect
            options={selectOptions}
            value={value}
            onChange={setValue}
          />
        </SelectorContainer>
        <ButtonContainer>
          <ConfirmButton onClick={onConfirm}>Confirm</ConfirmButton>
          <CancelButton onClick={hideModal}>Cancel</CancelButton>
        </ButtonContainer>
      </>
    );
  };

  const renderConfirmation = () => {
    return (
      <>
        <p>
          The selected brand will be applied to {placesToUpdate.length} places.
          Do you want to continue?
        </p>
        <ButtonContainer>
          <ConfirmButton onClick={onConfirm}>Confirm</ConfirmButton>
          <CancelButton onClick={hideModal}>Cancel</CancelButton>
        </ButtonContainer>
      </>
    );
  };

  const renderContent = () => {
    if (errorBrands)
      return renderError({
        message: "There was an error loading brands",
        error: errorBrands
      });
    if (editingError)
      return renderError({
        message: "There was an error setting the new brand",
        error: editingError
      });
    if (loadingBrands) return renderLoading({ message: "Loading Brands..." });
    if (askForConfirmation) return renderConfirmation();
    if (editingPlace)
      return renderLoading({ message: "Setting the new brand..." });
    return renderSelect();
  };

  return <Container>{renderContent()}</Container>;
};
