import styled from "styled-components";

import { Box, Input, ButtonSuccess } from "../utils/styles";

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: ${props => props.theme.lightGrey};
  justify-content: center;
  align-items: center;
`;

export const LoginForm = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30vw;
  padding: 20px;
  min-height: 220px;
`;

export const LoginTitle = styled.p`
  font-size: 18px;
  font-weight: 500;
`;

export const LoginInput = styled(Input)`
  width: 75%;
  margin: 5px;
`;

export const LoginButton = styled(ButtonSuccess)`
  margin-top: auto;
  width: 75%;
`;

export const LoginError = styled.p`
  font-size: 14px;
  color: red;
`;
