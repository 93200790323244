import styled from "styled-components";
import { SortDown } from "styled-icons/fa-solid/SortDown";
import { SortUp } from "styled-icons/fa-solid/SortUp";
import { Sort } from "styled-icons/fa-solid/Sort";

export const Container = styled.div.attrs(props => ({
  style: props.vStyle
}))`
  border: 1px solid #dee2e6;
  border-left: 0;
  border-top: 0;
  box-sizing: border-box;
  background-color: ${props => props.theme.blue};
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;

const icon = `
  width: 15px;
  height: 15px;
  :hover {
    cursor: pointer
  }
`;

export const Title = styled.span`
  color: white;
  font-size: 14px;
  font-weight: bold;
`;

export const SortIcon = styled(Sort)`
  color: ${props => props.theme.grey};
  ${icon}
`;

export const SortDownIcon = styled(SortDown)`
  color: white;
  ${icon}
`;

export const SortUpIcon = styled(SortUp)`
  color: white;
  ${icon}
`;
