import React, { useState } from "react";
import brandsStore from "../../store/brands";
import stagingPlacesStore from "../../store/stagingPlaces";

import {
  Container,
  ErrorContainer,
  ButtonContainer,
  ConfirmButton,
  CancelButton,
  WarningIcon,
  SelectorContainer,
  StyledSelect,
  StyledSpinner
} from "./styles";

export default props => {
  const { place, hideModal } = props;
  const {
    loading: editingPlace,
    status: editingStatus,
    error: editingError,
    makeRequest: editPlace
  } = stagingPlacesStore.usePutNewData();
  const selectedPlaces = stagingPlacesStore.useSelected();
  const {
    data: brands,
    loading: loadingBrands,
    error: errorBrands
  } = brandsStore.useData();

  const [value, setValue] = useState(
    place
      ? place.distributed.map(brand => {
          return {
            label: brand.mn_chain_brand_name,
            value: brand.mn_chain_brand_id
          };
        })
      : []
  );
  const [askForConfirmation, setAskForConfirmation] = useState(false);

  const placesToUpdated = [...selectedPlaces];
  if (place && !placesToUpdated.find(sp => sp.id === place.id))
    placesToUpdated.push(place);

  if (editingStatus === 200) hideModal();

  const onConfirm = () => {
    if (!askForConfirmation && placesToUpdated.length > 1)
      return setAskForConfirmation(true);
    setAskForConfirmation(false);

    editPlace({
      data: placesToUpdated.map(place => ({
        ...place,
        distributed: value.map(brand => brand.value)
      }))
    });
  };

  const renderError = ({ message, error }) => (
    <ErrorContainer>
      <WarningIcon />
      {message} <p>{error.message}</p>
    </ErrorContainer>
  );

  const renderLoading = ({ message }) => (
    <>
      <StyledSpinner /> {message}
    </>
  );

  const renderSelect = () => {
    const selectBrandsOptions = brands.map(brand => {
      return {
        label: brand.brand_name,
        value: brand.mn_brand_id
      };
    });

    return (
      <>
        <SelectorContainer>
          Brands
          <StyledSelect
            multi={true}
            width={700}
            options={selectBrandsOptions}
            value={value}
            onChange={setValue}
          />
        </SelectorContainer>
        <ButtonContainer>
          <ConfirmButton onClick={onConfirm}>Confirm</ConfirmButton>
          <CancelButton onClick={hideModal}>Cancel</CancelButton>
        </ButtonContainer>
      </>
    );
  };

  const renderConfirmation = () => {
    return (
      <>
        <p>
          The selected distributed brands will be applied to{" "}
          {placesToUpdated.length}
          places. Do you want to continue?
        </p>
        <ButtonContainer>
          <ConfirmButton onClick={onConfirm}>Confirm</ConfirmButton>
          <CancelButton onClick={hideModal}>Cancel</CancelButton>
        </ButtonContainer>
      </>
    );
  };

  const renderContent = () => {
    if (errorBrands)
      return renderError({
        message: "There was an error loading brands",
        error: renderError
      });
    if (editingError)
      return renderError({
        message: "There was an error setting the new distributed brands",
        error: editingError
      });
    if (loadingBrands) return renderLoading({ message: "Loading Brands..." });
    if (askForConfirmation) return renderConfirmation();
    if (editingPlace)
      return renderLoading({
        message: "Setting the new distributed brands..."
      });
    return renderSelect();
  };

  return <Container>{renderContent()}</Container>;
};
