import styled from "styled-components";
import { FileImport } from "styled-icons/fa-solid/FileImport";
import { Building } from "styled-icons/fa-solid/Building";
import { Tags } from "styled-icons/fa-solid/Tags";
import { GitRepoForked } from "styled-icons/boxicons-regular/GitRepoForked";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  grid-area: menu;
  z-index: 1;
  background-color: ${props => props.theme.blue};
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-out;
`;

export const MenuList = styled.ul`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
  padding-left: 50px;
`;

export const MenuItem = styled.li`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: white;
  margin-bottom: 10px;

  a {
    font-size: 20px;
    font-weight: 500;
    color: white;
    text-decoration: none;

    :hover {
      font-weight: 550;
    }
  }
`;

const icon = `
  margin-right: 5px;
  width: 22px;
  height: 22px;
  :hover {
    cursor: pointer;
    color: white;
  }
`;

export const ImportPlacesIcon = styled(FileImport)`
  color: ${props => props.theme.grey};
  ${icon}
`;
export const BrandsIcon = styled(Building)`
  color: ${props => props.theme.grey};
  ${icon}
`;
export const TagsIcon = styled(Tags)`
  color: ${props => props.theme.grey};
  ${icon}
`;
export const SourceIcon = styled(GitRepoForked)`
  color: ${props => props.theme.grey};
  ${icon}
`;
