import { useState } from "react";
import { getCurrentSession } from "./auth";
import axios from "axios";
import qs from "query-string";

let currentRequests = [];

export const useRequest = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [response, setResponse] = useState();
  const [status, setStatus] = useState();
  const [data, setData] = useState();
  const [query, setQuery] = useState();

  const resetState = () => {
    if (loading) setLoading(false);
    if (error) setError(null);
    if (data) setData(null);
    if (status) setStatus(null);
    if (response) setResponse(null);
    if (query) setQuery(null);
  };

  const makeRequest = async ({ endpoint, method = "GET", query, data }) => {
    try {
      if (method === "GET") {
        if (currentRequests.includes(endpoint)) return null;
        currentRequests.push(endpoint);
      }

      let endpointWithParams = endpoint;

      if (query) {
        endpointWithParams = `${endpointWithParams}?${qs.stringify(query)}`;
        setQuery(query);
      }

      const session = await getCurrentSession();
      const token = session.idToken.jwtToken;

      resetState();
      setLoading(true);
      setData(data);
      const res = await axios({
        url: endpointWithParams,
        method,
        data,
        headers: {
          Authorization: token
        }
      });
      setLoading(false);

      setResponse(res.data);
      setStatus(res.status);
    } catch (e) {
      console.log(e.response, e.error, e.message);
      setStatus(e.status);
      setError(e.response ? e.response.data : e);
    }
  };

  return {
    makeRequest,
    data,
    query,
    status,
    loading,
    error,
    response
  };
};
