import styled from "styled-components";
import spinner from "./spinner.gif";
import tick from "./tick.svg";

export const Box = styled.div`
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: ${props => props.theme.boxShadow};
`;

export const Input = styled.input`
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 1rem;
  line-height: 34px;
  color: black;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

export const Button = styled.button`
  padding: 7px 12px;
  color: white;
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0.25rem;
  outline: none;

  :hover {
    cursor: pointer;
  }
`;

export const Spinner = styled.img.attrs({
  src: spinner
})`
  size: 50px;
`;

export const Tick = styled.img.attrs({
  src: tick
})`
  width: 20px;
`;

export const ButtonSuccess = styled(Button)`
  border: 1px solid ${props => props.theme.blue};
  background: ${props => props.theme.blue};
`;

export const ButtonDanger = styled(Button)`
  border: 1px solid ${props => props.theme.lightRed};
  background: ${props => props.theme.red};
`;
