import React, { useState } from "react";
import {
  Container,
  ButtonContainer,
  ConfirmButton,
  CancelButton,
  ConfirmationContainer
} from "./styles";

export default ({ geocode, places, hideModal }) => {
  const manuallyEditedFieldsCount = places
    .filter(place => place.mn_chain_brand_id)
    .reduce((count, place) => {
      if (place.street_name_edited) return count + 1;
      if (place.street_number_edited) return count + 1;
      if (place.full_address_edited) return count + 1;
      if (place.postal_code_edited) return count + 1;
      if (place.country_code_edited) return count + 1;
      if (place.county_name_edited) return count + 1;
      if (place.city_name_edited) return count + 1;
      if (place.region_code_edited) return count + 1;
      if (!place.mn_chain_brand_id) return count + 1;
      return count;
    }, 0);

  const noBrandCount = places.reduce((count, place) => {
    if (!place.mn_chain_brand_id) return count + 1;
    return count;
  }, 0);

  const getInitalWarning = () => {
    if (noBrandCount > 0) {
      if (places.length > noBrandCount) return "noBrandWarning";
      return "noBrandError";
    }
    return "manuallyEditedWarning";
  };

  const [warning, setWarning] = useState(getInitalWarning());

  const onYes = () => {
    if (
      warning === "manuallyEditedWarning" ||
      (warning === "noBrandWarning" && manuallyEditedFieldsCount === 0)
    ) {
      geocode({
        places: places.filter(place => place.mn_chain_brand_id),
        query: { overwrite: true }
      });
      hideModal();
    } else setWarning("manuallyEditedWarning");
  };
  const onNo = () => {
    if (warning === "manuallyEditedWarning")
      geocode({
        places: places.filter(place => place.mn_chain_brand_id),
        query: { overwrite: false }
      });
    hideModal();
  };

  const getMessage = () => {
    if (warning === "noBrandError")
      return `Places without brand cannot be geocoded`;
    if (warning === "noBrandWarning")
      return `There are ${noBrandCount} places without brand that will not be geocoded. Do you want to continue?`;
    if (warning === "manuallyEditedWarning")
      return `There are ${manuallyEditedFieldsCount} places with manually edited fields.  Do you want the geocoder to overwrite them?`;
  };

  return (
    <Container>
      <ConfirmationContainer>
        <p>{getMessage()}</p>
        <ButtonContainer>
          {warning === "noBrandError" ? (
            <CancelButton onClick={hideModal}>Close</CancelButton>
          ) : (
            <>
              <ConfirmButton onClick={onYes}>Yes</ConfirmButton>
              <ConfirmButton onClick={onNo}>No</ConfirmButton>
              <CancelButton onClick={hideModal}>Cancel</CancelButton>
            </>
          )}
        </ButtonContainer>
      </ConfirmationContainer>
    </Container>
  );
};
