import React, { useState } from "react";
import { navigate } from "@reach/router";
import {
  Container,
  LoginForm,
  LoginInput,
  LoginButton,
  LoginTitle,
  LoginError
} from "./styles";

import { singIn, completeNewPassword } from "../utils/auth";
import { useInputValue } from "../utils/hooks";

export default () => {
  const [password, setPassword] = useInputValue();
  const [username, setUsername] = useInputValue();
  const [newPassword, setNewPassword] = useInputValue();
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useInputValue();
  const [error, setError] = useState();
  const [screen, setScreen] = useState("login");

  const onSubmit = async e => {
    e.preventDefault();
    try {
      await singIn({ username, password });
      navigate("/");
    } catch (e) {
      if (e.code === "NEW_PASSWORD_REQUIRED") {
        setScreen("newpassword");
      } else setError(e.message);
    }
  };

  const onSubmitNewPassword = async e => {
    e.preventDefault();
    if (newPassword !== newPasswordConfirmation)
      return setError("The password don't match");
    try {
      await completeNewPassword({ password: newPassword });
      navigate("/");
    } catch (e) {
      setError(e.message);
    }
  };

  const renderLoginForm = () => (
    <LoginForm as="form" onSubmit={onSubmit}>
      <LoginTitle>Sign in to your account </LoginTitle>
      <LoginInput placeholder="Email" onChange={setUsername} value={username} />
      <LoginInput
        placeholder="Password"
        autocomplete="current-password"
        type="password"
        onChange={setPassword}
        value={password}
      />
      <LoginError>{error}</LoginError>
      <LoginButton> SIGN IN </LoginButton>
    </LoginForm>
  );

  const renderNewPassword = () => (
    <LoginForm as="form" onSubmit={onSubmitNewPassword}>
      <LoginTitle>Please create a new password</LoginTitle>
      <LoginInput
        placeholder="New Password"
        type="password"
        onChange={setNewPassword}
        value={newPassword}
      />
      <LoginInput
        placeholder="New Password Confirmation"
        type="password"
        onChange={setNewPasswordConfirmation}
        value={newPasswordConfirmation}
      />
      <LoginError>{error}</LoginError>
      <LoginButton> SUBMIT </LoginButton>
    </LoginForm>
  );

  return (
    <Container>
      {screen === "login" ? renderLoginForm() : renderNewPassword()}
    </Container>
  );
};
