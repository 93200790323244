import { useEffect } from "react";
import { createDataStore } from "./common";
import { useRequest } from "../utils/request";
import stagingPlacesStore from "./stagingPlaces";

const importPlacesStore = createDataStore({
  storeKey: "importPlaces",
  idKey: "mn_import_id",
  filterField: "mn_chain_brand_name",
  endpoint: process.env.REACT_APP_PLACES_API_HOST + "/importPlaces",
  deleteEndpoint: process.env.REACT_APP_PLACES_API_HOST + "/deleteImportPlaces"
});

const useData = () => {
  const selectedStagingPlaces = stagingPlacesStore.useSelected();
  const { data, ...rest } = importPlacesStore.useData();

  let filteredData = data;
  if (selectedStagingPlaces && selectedStagingPlaces.length > 0) {
    filteredData = data.filter(place =>
      selectedStagingPlaces.find(
        sPlace => sPlace.mn_import_id === place.mn_import_id
      )
    );
  }

  return {
    data: filteredData,
    ...rest
  };
};

const usePostNewData = () => {
  const { makeRequest, response, ...rest } = useRequest();

  const insertNewImportPlaces = importPlacesStore.useInsertNewData();
  const insertNewStagingPlaces = stagingPlacesStore.useInsertNewData();

  useEffect(() => {
    if (response) {
      const { importPlaces, stagingPlaces } = response;
      if (importPlaces) insertNewImportPlaces({ data: importPlaces });
      if (stagingPlaces) insertNewStagingPlaces({ data: stagingPlaces });
    }
  }, [response]);

  const insertAndValidate = ({ data, query }) => {
    makeRequest({
      data,
      query,
      method: "POST",
      endpoint: process.env.REACT_APP_PLACES_API_HOST + "/importPlaces"
    });
  };

  return {
    response,
    makeRequest: insertAndValidate,
    ...rest
  };
};

export default {
  ...importPlacesStore,
  useData,
  usePostNewData
};
