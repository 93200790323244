import styled from "styled-components";

import { ButtonDanger, Spinner } from "../utils/styles";

export const Container = styled.div`
  width: 100%;
`;

export const TableActions = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 5px;
  align-items: center;
  justify-content: space-between;
`;

export const DeleteButton = styled(ButtonDanger)`
  padding: 5px;
  ${props => props.disabled && "background:" + props.theme.grey}
  ${props => props.disabled && "border-color:" + props.theme.grey}
  :hover {
    cursor: ${props => (props.disabled ? "auto" : "pointer")};
  }
`;

export const MessageContainer = styled.div`
  margin-left: 2px;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSpinner = styled(Spinner)`
  width: 20px;
  height: 20px;
  margin-right: 2px;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;
