import React, { useState, useEffect, useRef } from "react";
import { Dropdown, DropdownMenu } from "styled-bootstrap-components";
import { DropdownToggle, Action } from "./styles";

export default ({ title = "Actions", disabled = false, actions = [] }) => {
  const [hidden, setHidden] = useState(true);
  const dropdownRef = useRef();

  const toggle = () => {
    setHidden(!hidden);
  };

  const handleClickOutside = e => {
    console.log("handle click outside");
    if (dropdownRef.current && !dropdownRef.current.contains(e.target))
      setHidden(true);
  };

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    //return window.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <Dropdown ref={dropdownRef}>
      <DropdownToggle onClick={toggle} dropdownToggle disabled={disabled}>
        {title}
      </DropdownToggle>
      <DropdownMenu hidden={hidden}>
        {actions.map(action => (
          <Action key={action.name} onClick={action.onClick}>
            {action.name}
          </Action>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};
