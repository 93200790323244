import React from "react";
import { Container, Title, SortIcon, SortUpIcon, SortDownIcon } from "./styles";

export default ({ field, onSort, isSorted, sortDirection, vStyle }) => {
  const isSortable = !field.sortable === false;
  const onSortClick = () => {
    if (!isSortable) return;
    if (!isSorted) return onSort({ field, direction: "ASC" });
    if (sortDirection === "ASC") return onSort({ field, direction: "DESC" });
    return onSort({ field: null, direction: null });
  };

  const renderSortIcon = () => {
    if (!isSorted) return <SortIcon />;
    if (sortDirection === "ASC") return <SortUpIcon />;
    return <SortDownIcon />;
  };

  return (
    <Container vStyle={vStyle} onClick={onSortClick}>
      <Title>{field.label}</Title>
      {isSortable && renderSortIcon()}
    </Container>
  );
};
