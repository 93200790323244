import React, { useState, useEffect, useRef } from "react";
import { useInputValue } from "../../utils/hooks";

import { Cell, Input } from "./styles";

export default React.memo(({ field, row, vStyle }) => {
  const value = row[field.name];
  const [editable, setEditable] = useState(false);
  const [initValue, setInitValue] = useState(value);
  const [inputValue, setInputValue, resetInputValue] = useInputValue(value);
  const inputEl = useRef(null);

  if (value !== initValue) {
    resetInputValue(value);
    setInitValue(value);
  }

  const handleClickOutside = e => {
    if (editable && inputEl.current && !inputEl.current.contains(e.target)) {
      editField();
    }
  };

  const editField = () => {
    console.log(initValue, inputValue);
    if (initValue !== inputValue) {
      field.onEdit({ ...row, [field.name]: inputValue });
      setInitValue(inputValue);
    }
    setEditable(false);
  };

  const onKeyPress = e => {
    if (e.key === "Enter") {
      editField();
    }
  };

  useEffect(() => {
    if (editable) window.addEventListener("mousedown", handleClickOutside);
    return window.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const error = field.showError && field.showError(row);
  const warning = field.showWarning && field.showWarning(row);
  const success = field.showSuccess && field.showSuccess(row);
  const info = field.showInfo && field.showInfo(row);
  const renderValue = field.format ? field.format(initValue) : initValue;

  const renderCellContent = () => {
    if (field.render) return field.render(row);
    if (editable)
      return (
        <Input
          value={inputValue}
          onChange={setInputValue}
          onBlur={editField}
          onKeyPress={onKeyPress}
          autoFocus
        />
      );
    return renderValue;
  };

  const getTooltipType = () => {
    if (field.tooltipType) return field.tooltipType(row);

    if (error) return "error";
    if (success) return "success";
    if (warning) return "warning";
    return "info";
  };

  const getTooltipMessage = () => {
    if (field.showTooltip) return field.showTooltip(row);
    return error || success || warning || info;
  };

  return (
    <Cell
      error={error}
      warning={warning}
      info={info}
      success={success}
      data-tip={getTooltipMessage()}
      data-type={getTooltipType()}
      data-for="tooltip"
      ref={inputEl}
      vStyle={vStyle}
      onClick={() => {
        if (field.onEdit) setEditable(true);
        field.onClick && field.onClick(row);
      }}
    >
      {renderCellContent()}
    </Cell>
  );
});
