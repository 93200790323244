import styled from "styled-components";

const getBackgroundColor = props => {
  if (props.error) return props.theme.lightRed;
  if (props.success) return props.theme.green;
  if (props.warning) return props.theme.orange;
  return "white";
};

export const Cell = styled.div.attrs(props => ({
  style: props.vStyle
}))`
  font-size: 12px;
  text-align: center;
  color: ${props =>
    (props.error || props.warning || props.info || props.success) && "white"};
  border-style: solid;
  box-sizing: border-box;
  border: 1px solid #dee2e6;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  background-color: ${getBackgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

export const Input = styled.input`
  border: 0;
  text-align: center;
  font-size: 13px;
  width: 100%;
  outline: none;
`;
